import React, { useState, useEffect } from "react";
import Users from "./Users";
import Customers from "./Customers";
import Furnaces from "./Furnaces";
// import Customers from "./Customers";
// import Users from "./Users";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeTab, setActiveTab] = useState("customerTab");
  const [currentUser, setCurrentUser] = useState(null);



  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setCurrentUser(user);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleButtonClick = () => {
    alert("Button clicked!");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (!currentUser) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg text-gray-600">Loading...</p>
      </div>
    );
  }

  // Restrict access for role_id 1, 2, or 3
  if (
    currentUser.role.role_id === 1 ||
    currentUser.role.role_id === 2 ||
    currentUser.role.role_id === 3
  ) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-black-600">
          You don’t have access to this page.
        </p>
      </div>
    );
  }



  return (
    <div>
      <div className="flex border-b px-4 border-gray-200 dark:border-gray-700 justify-between items-center">
        <ul className="flex -mb-px text-sm font-medium text-center overflow-auto">
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("customerTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "customerTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="customerTab"
              aria-selected={activeTab === "customerTab"}
            >
              CUSTOMERS
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("userTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "userTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="userTab"
              aria-selected={activeTab === "userTab"}
            >
              USERS
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("furnaceTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "furnaceTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="furnaceTab"
              aria-selected={activeTab === "furnaceTab"}
            >
              FURNACES
            </button>
          </li>
        </ul>
      </div>
      <div>
        <div>
          {activeTab === "customerTab" && <Customers />}
          {activeTab === "userTab" && <Users />}
          {activeTab === "furnaceTab" && <Furnaces />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
