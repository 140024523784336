import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from "recoil";
import { former_cost, former_cost_nrm, trial_name, materialType } from "../../../recoil/atom";
import { formerCostCampaignNRMSelector, formerCostCampaignSelector, formerCostPerTonSelector, savingInFormerCostSelector, formerCostPerTonNRMSelector } from "../../../recoil/selector";
import Helper from '../../../service/Helper';
const FormerCost = () => {
  const [formerCost, setFormerCost] = useRecoilState(former_cost)
  const [formerCostNRM, setFormerCostNRM] = useRecoilState(former_cost_nrm)
  const [trialName] = useRecoilState(trial_name)
  const [type, setType] = useRecoilState(materialType)

  const FormerCostCampaign = useRecoilValue(formerCostCampaignSelector)
  const formerCostPerTon = useRecoilValue(formerCostPerTonSelector)
  const FormerCostCampaignNRM = useRecoilValue(formerCostCampaignNRMSelector)
  const formerCostPerTonNRM = useRecoilValue(formerCostPerTonNRMSelector)
  const savingInFormerCost = useRecoilValue(savingInFormerCostSelector)
  console.log(FormerCostCampaign, 'FormerCostCampaign')

  const handleFormerCostChange = (name, value) => {
    setFormerCost((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFormerCostNRMChange = (name, value) => {
    setFormerCostNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }


  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (Array.isArray(array[i])) {
        // If the current item is an array, recursively search within it
        const result = doesKeyExist(array[i], key)
        if (result) return result
      } else if (typeof array[i] === 'object') {
        // If the current item is an object, check if it has the desired key
        if (array[i].hasOwnProperty(key)) {
          return array[i]
        }
      }
    }
    return false
  }

  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Former cost per piece
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="former_cost_per_piece"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former cost per piece - (dia)
          </label>
          <input
            type="text"
            id="former_cost_per_piece"
            name="former_cost_per_piece"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter cost"
            value={formerCost.former_cost_per_piece}
            onChange={(event) =>
              handleFormerCostChange('former_cost_per_piece', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="FormerCostCampaign"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total former cost in a campaign - (dia)
          </label>
          <input
            type="text"
            id="FormerCostCampaign"
            name="FormerCostCampaign"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former cost"
            value={FormerCostCampaign ? FormerCostCampaign : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="formerCostPerTon"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former cost per ton of steel produced <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="formerCostPerTon"
            name="formerCostPerTon"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former cost"
            value={formerCostPerTon ? formerCostPerTon : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="former_cost_per_piece"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings in Former cost - <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="former_cost_per_piece"
            name="former_cost_per_piece"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former cost"
            value="NA"
            onChange={(event) =>
              handleFormerCostChange('former_cost_per_piece', event.target.value)
            }
          />
        </div>
      </div>
      <h2 className="text-l font-medium text-black-400 mb-4 mt-4 uppercase">NRM</h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="former_cost_per_piece"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former cost per piece - (dia)
          </label>
          <input
            type="text"
            id="former_cost_per_piece"
            name="former_cost_per_piece"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter cost"
            value={formerCostNRM.former_cost_per_piece}
            onChange={(event) =>
              handleFormerCostNRMChange(
                'former_cost_per_piece',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="FormerCostCampaignNRM"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total former cost in a campaign - (dia)
          </label>
          <input
            type="text"
            id="FormerCostCampaignNRM"
            name="FormerCostCampaignNRM"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter cost"
            value={FormerCostCampaignNRM ? FormerCostCampaignNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former cost per ton of steel produced - <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="formerCostPerTonNRM"
            name="formerCostPerTonNRM"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter cost"
            value={formerCostPerTonNRM ? formerCostPerTonNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="savingInFormerCost"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings in Former cost - <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="savingInFormerCost"
            name="savingInFormerCost"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter cost"
            value={savingInFormerCost ? savingInFormerCost : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
    </>
  );
};

export default FormerCost;
