import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { yield_improvement_nrm, yield_improvement, trial_name, materialType } from "../../../recoil/atom";
import { costSavingsDueToYieldImprovementSelector } from "../../../recoil/selector"
import Helper from "../../../service/Helper";
const YieldImprovement = () => {
  const [yieldImprovement, setYieldImprovement] =
    useRecoilState(yield_improvement)
  const [yieldImprovementNRM, setYieldImprovementNRM] = useRecoilState(
    yield_improvement_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const [type, setType] = useRecoilState(materialType)

  const costSavingsDueToPower = useRecoilValue(
    costSavingsDueToYieldImprovementSelector
  )
  console.log('Cost Savings Due to Yield Improvement:', costSavingsDueToPower)

  const handleYieldImprovement = (name, value) => {
    setYieldImprovement((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleYieldImprovementNRM = (name, value) => {
    setYieldImprovementNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }


  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };


  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S5 : Savings due to Yield improvement
      </h2>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield Benefit <br />(%)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value="NA"
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market price of Scrap or DRI per ton <br />(dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter price"
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to yield benefit (dia / MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value="NA"
          onChange={() => { }}
        />
      </div>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>
      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="yield_benefit"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield Benefit <br />(%)
          </label>
          <input
            type="text"
            id="yield_benefit"
            name="yield_benefit"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Value"
            value={yieldImprovementNRM.yield_benefit}
            onChange={(event) =>
              handleYieldImprovementNRM('yield_benefit', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="market_price_of_scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market price of Scrap or DRI per ton <br />(dia / MT)
          </label>
          <input
            type="text"
            id="market_price_of_scrap"
            name="market_price_of_scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter price"
            value={yieldImprovementNRM.market_price_of_scrap}
            onChange={(event) =>
              handleYieldImprovementNRM(
                'market_price_of_scrap',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="costSavingsDueToPower"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to yield benefit (dia / MT)
        </label>
        <input
          type="text"
          id="costSavingsDueToPower"
          name="costSavingsDueToPower"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={costSavingsDueToPower}
          onChange={(event) =>
            handleYieldImprovementNRM(
              'savings_due_to_yield_benefit',
              event.target.value
            )
          }
        />
      </div>
    </>
  );
};

export default YieldImprovement;
