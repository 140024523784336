import React, { useState, useRef, useEffect } from 'react'
// import ViewIcon from '../../assets/icons/ic_edit.svg'
// import DownloadIcon from '../../assets/icons/ic_download.svg'
// import DeleteIcon from '../../assets/icons/ic_delete.svg'
import BoxChart from "../../assets/images/SVGIcon/BoxChart.svg";
import ScatterChart from "../../assets/images/SVGIcon/Scatter-Charts.svg";
import LineChart from "../../assets/images/SVGIcon/LineChart.svg";
import DailyTrialIcon from "../../assets/images/SVGIcon/BoxChart.svg"
import NRMIcon from "../../assets/images/SVGIcon/Scatter-Charts.svg"
import ErosionIcon from "../../assets/images/SVGIcon/LineChart.svg"
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Plotly from 'plotly.js-dist/plotly'
import axios from 'axios'
import API from './../../service/API'
import { useNavigate } from 'react-router-dom'
import menuDot from "../../assets/images/icons/menuDot.svg";

export default function ReportsCard({ reportCardDetails, selectedTemplate }) {
  const [openDropdownId, setOpenDropdownId] = useState(null); // Track open dropdown
  const dropdownRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [cardIdDetails, setCardId] = useState(null)
  const [chartData, setChartData] = useState([])
  const [chartsRendered, setChartsRendered] = useState(false) // State to track if charts have been rendered
  const navigate = useNavigate()
  const chartContainerRef1 = useRef(null) // Ref for the first chart container
  const chartContainerRef2 = useRef(null)
  const popupRef = useRef(null)
  const [iconsData, setIconsData] = useState([])
  // Toggle dropdown visibility for the selected card
  // const toggleDropdown = (id) => {
  //   setOpenDropdownId((prevId) => (prevId === id ? null : id));
  // };
  const handleDelete = async () => {
    //console.log('Delete button clicked!');
    try {
      const cardId = localStorage.getItem('reportCardId') // Assuming this is set elsewhere or fetched
      const url = `${API.DELETE_REPORTS}/${cardId}`
      const response = await axios.put(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      window.location.reload()
    } catch (error) {
      //console.error("Error fetching chart data:", error);
    }
  }

  const handleMenuOpen = (event, item) => {
    setOpenDropdownId((prevId) => (prevId === item.id ? null : item.id));
    event.stopPropagation() // Prevents event bubbling
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    //console.log("itemdetails", item.id);
    localStorage.setItem('reportCardId', item.id)
    setCardId(item.id)
  }

  const handleView = async () => {
    navigate('/ReportsDataSetup')
  }

  const handleDownload = async () => {
    navigate('./pdf')
  }

  useEffect(() => {
    // Fetch chart data when component mounts
    fetchChartData()
  }, [])

  const fetchChartData = async () => {
    try {
      const cardId = localStorage.getItem('reportCardId') // Assuming this is set elsewhere or fetched
      const url = `${API.GET_GRAPH}/${cardId}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const chartData = response.data
      setChartData(chartData)
    } catch (error) {
      //console.error("Error fetching chart data:", error);
    }
  }

  useEffect(() => {
    // Function to fetch icons for a specific cardId
    const fetchIcons = async (cardId) => {
      try {
        const url = `${API.GET_REPORTSICON}/${cardId}`
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        if (response.status === 200) {
          const transformedIcons = response.data.map((item) => item.template_id)
          setIconsData((prevState) => ({
            ...prevState,
            [cardId]: transformedIcons,
          }))
        } else {
          console.warn(
            `Unexpected status code ${response.status} for URL: ${url}`
          )
          setIconsData((prevState) => ({
            ...prevState,
            [cardId]: [], // Empty array indicating no icons fetched
          }))
        }
      } catch (error) {
        //console.error("Error fetching icons:", error);
        setIconsData((prevState) => ({
          ...prevState,
          [cardId]: [], // Empty array indicating no icons fetched
        }))
      }
    }

    // Fetch icons for all cards when reportCardDetails changes
    if (reportCardDetails) {
      reportCardDetails.forEach((item) => {
        if (item.id) {
          fetchIcons(item.id)
        }
      })
    }
  }, [reportCardDetails])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const renderIcons = (cardId) => {
    const icons = iconsData[cardId] || []
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        {icons.includes(1) && (
          <img
            src={DailyTrialIcon}
            alt="Daily Trial Icon"
            style={{ marginRight: '8px' }}
          />
        )}
        {icons.includes(2) && (
          <img src={NRMIcon} alt="NRM Icon" style={{ marginRight: '8px' }} />
        )}
        {icons.includes(3) && <img src={ErosionIcon} alt="Erosion Icon" />}
      </div>
    )
  }
  useEffect(() => {
    // Render charts when chartData changes
    if (chartData.length > 0) {
      renderCharts()
    }
  }, [chartData])

  const renderCharts = () => {
    chartData.forEach((chart) => {
      switch (chart.id) {
        case 1:
          renderPlotlyChart(chart, chartContainerRef1.current)
          break
        case 2:
          renderPlotlyChart(chart, chartContainerRef2.current)
          break
        default:
          break
      }
    })
    // Set state to indicate charts are rendered
    setChartsRendered(true)
  }

  const renderPlotlyChart = (chart, containerRef) => {
    const data = chart.data
    const layout = { title: chart.title }

    Plotly.newPlot(containerRef, data, layout)
  }

  const handlePDF = () => {
    // Check if charts have been rendered
    if (
      chartsRendered &&
      chartContainerRef1.current &&
      chartContainerRef2.current
    ) {
      html2canvas(chartContainerRef1.current)
        .then((canvas1) => {
          html2canvas(chartContainerRef2.current)
            .then((canvas2) => {
              const pdf = new jsPDF('landscape', 'mm', 'a4')
              const imgData1 = canvas1.toDataURL('image/png')
              const imgData2 = canvas2.toDataURL('image/png')

              // Calculate image dimensions based on PDF page size
              const imgWidth = pdf.internal.pageSize.getWidth()
              const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width
              const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width

              // Add first image to PDF
              pdf.addImage(
                imgData1,
                'PNG',
                10,
                10,
                imgWidth - 20,
                imgHeight1 - 20
              ) // Adjusting margins
              pdf.addPage() // Add new page for the second chart

              // Add second image to PDF
              pdf.addImage(
                imgData2,
                'PNG',
                10,
                10,
                imgWidth - 20,
                imgHeight2 - 20
              ) // Adjusting margins

              pdf.save('combined_charts.pdf')
            })
            .catch((error) => {
              //console.error('Error rendering chart 2 to canvas:', error);
            })
        })
        .catch((error) => {
          //console.error('Error rendering chart 1 to canvas:', error);
        })
    } else {
      //console.error('One or both chart containers are not available.');
    }
  }

  useEffect(() => {
    // Call handlePDF when charts are rendered and when chartData changes
    if (chartsRendered) {
      handlePDF()
    }
  }, [chartsRendered, chartData])
  return (
    <div className='grid gap-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1'>
      {reportCardDetails &&
        reportCardDetails.map((item) => (
          <div className="card-grid" key={item.id}
          >
            <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div className="flex items-start justify-between mb-2 pl-4 pt-4 relative">
                <div>
                  <p className="text-sm text-gray-800 font-medium py-1 mb-0">
                    {item.report_name}
                  </p>
                </div>
                <button
                  type="button"
                  className="flex mx-3 text-sm dark:focus:ring-gray-600"
                  id="menuDot"
                  // onClick={() => toggleDropdown(item.id)} // Ensure unique id
                  onClick={(event) => handleMenuOpen(event, item)}
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="w-6 h-6 rounded-full"
                    src={menuDot ?? ""}
                    alt="menu"
                  />
                </button>
                {openDropdownId === item.id && (
                  <div
                    id="userDropdown"
                    ref={dropdownRef}
                    className="absolute z-10 top-4 right-5 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200 w-full"
                      aria-labelledby="avatarButton"
                    >
                      <li>
                        <button onClick={handleView} className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Edit Data Setup
                        </button>
                      </li>
                      <li>
                        <button onClick={handleDelete} className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {item.description && (
                <div className="mb-2 w-full pl-4">
                  <p className="text-sm text-gray-800 font-medium py-1 mb-0">
                    {item.description}
                  </p>
                </div>
              )}
              {item.customer && (<div className="mb-2 w-full pl-4">
                <p className="text-sm text-gray-800 font-medium py-1 mb-0">
                  {item.customer}
                </p>
              </div>)}
              {item.data_template && (<div className="mb-2 w-full pl-4">
                <p className="text-sm text-gray-800 font-medium py-1 mb-0">
                  {item.data_template}
                </p>
              </div>)}

              <p className="text-xs font-normal text-gray-400 px-4 pb-4">
                {renderIcons(item.id)}
              </p>
              <div className="w-full px-4 py-2 border-t flex justify-between items-center">
                <p className="text-xs font-normal text-gray-400">
                  Last Edited : {item.updated_at}
                </p>
                <div className="flex items-center">

                  {/* <img
              className="w-8 h-8 rounded-full ml-2"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gough.png"
              alt="user"
            /> */}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>

  );
}
