import React from 'react';
import { useRecoilState } from 'recoil';
import { general_Details } from '../../recoil/atom';
import DatePicker from "react-datepicker";
const GeneralDetailsWeb = () => {
  const [GeneralDetails, setGeneralDetails] = useRecoilState(general_Details)
  console.log(GeneralDetails, "GeneralDetails89")

  const handleChange = (name, value) => {
    if (name === 'mobile_number') {
      const digitsOnly = value.replace(/\D/g, ''); // Remove non-digit characters
      if (digitsOnly.length > 10) return; // Prevent input if more than 10 digits
      setGeneralDetails((prevGeneralDetails) => ({
        ...prevGeneralDetails,
        [name]: digitsOnly,
      }));
    } else if (name === 'date') {
      // Ensure date is a valid Date object or null
      setGeneralDetails((prevGeneralDetails) => ({
        ...prevGeneralDetails,
        [name]: value instanceof Date && !isNaN(value) ? value : null,
      }));
    } else {
      setGeneralDetails((prevGeneralDetails) => ({
        ...prevGeneralDetails,
        [name]: value,
      }));
    }
  };


  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">General Details</h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label htmlFor="customer_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Name of the Customer
          </label>
          <input
            type="text"
            id="customer_name"
            name="customer_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Name"
            value={
              GeneralDetails.customer_name ? GeneralDetails.customer_name : ''
            }
            onChange={(e) => handleChange("customer_name", e.target.value)}
          />
        </div>
        <div className="w-1/2">
          <label htmlFor="contact_person" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Contact Person
          </label>
          <input
            type="text"
            id="contact_person"
            name="contact_person"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Name"
            value={
              GeneralDetails.contact_person ? GeneralDetails.contact_person : ''
            }
            onChange={(e) => handleChange("contact_person", e.target.value)}
          />
        </div>

      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobile_number"
            name="mobile_number"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Number"
            value={GeneralDetails.mobile_number}
            onChange={(e) => handleChange("mobile_number", e.target.value)}
          />
        </div>
        <div className="w-1/2">
          <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Date of Data Collection
          </label>
          <DatePicker
            id="date"
            selected={GeneralDetails.date} // Default to null if date is invalid
            onChange={(date) => handleChange("date", date)}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select Date"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

        </div>
      </div>
    </>
  );
};

export default GeneralDetailsWeb;
