import React, { useState, forwardRef, useImperativeHandle } from "react";
import rightArrow from "./../../../assets/images/icons/RightArrow.svg";
import LaborCost from "./LaborCost";
import FormerCost from "./FormerCost";
import Productivity from "./Productivity";
import Energy from "./Energy";
import YieldImprovement from "./YieldImprovement";
import Alloy from "./Alloy";
import TangibleBenfit from "./TangibleBenfit";
import MetallurgicalBenefits from "./MetallurgicalBenefits";
import SinteringHeats from "./SinteringHeats";

const SaveDetailsLayout = forwardRef((props, ref) => {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    { id: 1, label: "Labor", component: <LaborCost /> },
    { id: 2, label: "Former", component: <FormerCost /> },
    { id: 3, label: "Increased Productivity", component: <Productivity /> },
    { id: 4, label: "Energy", component: <Energy /> },
    { id: 5, label: "Yield", component: <YieldImprovement /> },
    { id: 6, label: "Alloy", component: <Alloy /> },
    { id: 7, label: "Tangible Benefits", component: <TangibleBenfit /> },
    { id: 8, label: "Metallurgical Benefits", component: <MetallurgicalBenefits /> },
    { id: 9, label: "Sintering Heats", component: <SinteringHeats /> },
  ];

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  useImperativeHandle(ref, () => ({
    handleNext,
    handlePrevious,
  }));

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200 dark:border-gray-700 pr-4">
        <ul className="flex -mb-px text-sm font-medium text-center overflow-auto py-2 track-form-list">
          {steps.map((step, index) => (
            <li key={step.id} className="me-2" role="presentation">
              <div className="flex items-center">
                <button
                  onClick={() => setCurrentStep(step.id)}
                  className={`inline-block p-1 whitespace-nowrap font-normal rounded-t-lg ${
                    currentStep === step.id
                      ? "text-custom-orange"
                      : "text-gray-500"
                  }`}
                  type="button"
                  role="tab"
                  aria-controls={step.id}
                  aria-selected={currentStep === step.id}
                >
                  <span className="flex items-center justify-center">
                    <span
                      className={`flex items-center justify-center w-5 h-5 me-2 text-xs bg-gray-100 border rounded-full shrink-0 ${
                        currentStep === step.id
                          ? "border-custom-orange text-custom-orange bg-custom-orange-light"
                          : "border-gray-300"
                      }`}
                    >
                      {index + 1}
                    </span>
                    {step.id === 1
                      ? "Labor"
                      : step.id === 2
                      ? "Former"
                      : step.id === 3
                      ? "Increased Productivity"
                      : step.id === 4
                      ? "Energy"
                      : step.id === 5
                      ? "Yield"
                      : step.id === 6
                      ? "Alloy"
                      : step.id === 7
                      ? "Tangible Benefits"
                      : step.id === 8
                      ? "Metallurgical Benefits"
                      : "Sintering Heats"}
                  </span>
                </button>
                {index < steps.length - 1 && (
                  <img src={rightArrow} alt="arrow" />
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{ height: "320px" }}
        className="mt-4 w-full overflow-y-auto pb-4 pr-4"
      >
        {steps[currentStep - 1]?.component}
      </div>
    </div>
  );
});

export default SaveDetailsLayout;
