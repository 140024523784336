import React, { useState } from "react";
import GeneralDetailsWeb from "./GeneralDetailsWeb";
import FurnaceDetailsWeb from "./FurnaceDetailsWeb";
import ChargeMixDetailsWeb from "./ChargeMixDetailsWeb";
import CarbonWeb from "./CarbonWeb";
import AlloyingAdditionWeb from "./AlloyingAdditionWeb";
import OtherParametersWeb from "./OtherParametersWeb";
import { RecoilRoot, useRecoilState } from "recoil";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rightArrow from "./../../assets/images/icons/RightArrow.svg";
import AlertDialog from "../Common/AlertDialog";
import {
  general_details,
  furnace_details,
  charge_mix_details,
  carbon,
  alloying_addition,
  other_parameters,
  trial_name,
  carbon_type,
} from "../../recoil/atom";
import axios from "axios";
import API from "../../service/API";
import ApproveStatus from "../Status/ApproveStatus";
import SubmittedStatus from "../Status/SubmittedStatus";
import DraftStatus from "../Status/DraftStatus";

const DailyTrialLayout = ({ closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const isEdit = localStorage.getItem("Trial");
  const [generalDetails, setGeneralDetails] = useRecoilState(general_details);
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(furnace_details);
  const [chargeMixDetails, setChargeMixDetails] =
    useRecoilState(charge_mix_details);
  const [carbonObj, setCarbonObj] = useRecoilState(carbon);
  const [alloyingAddition, setAlloyingAddition] =
    useRecoilState(alloying_addition);
  const [otherParameters, setOtherParameters] =
    useRecoilState(other_parameters);
  console.log(generalDetails, "generalDetails", furnaceDetails, "furnaceDetails", chargeMixDetails, "chargeMixDetails")
  const [loading, setLoading] = useState(false); // Loader state
  const [error, setError] = useState(null); // Error state
  const [trialName] = useRecoilState(trial_name);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const access_token = localStorage.getItem("accesstoken");
  const navigate = useNavigate();
  const [statusDraft, setStatusDraft] = useState(false);
  const [statusSubmitted, setStatusSubmitted] = useState(false);
  const [statusApproved, setStatusApproved] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  console.log(validationErrors, "errors")

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const [db, setDb] = useState(null);
  const steps = [
    { id: 1, component: <GeneralDetailsWeb validationErrors={validationErrors} /> },
    { id: 2, component: <FurnaceDetailsWeb /> },
    { id: 3, component: <ChargeMixDetailsWeb /> },
    { id: 4, component: <CarbonWeb /> },
    { id: 5, component: <AlloyingAdditionWeb /> },
    { id: 6, component: <OtherParametersWeb /> },
  ];
  const [activeTab, setActiveTab] = useState(steps[0].id); 


  useEffect(() => {
    const fetchData = async () => {
      if (isEdit === 'edit' || isEdit === 'view') {
        console.log('true')
        setLoading(true)
        setError(null)
        try {
          const selectedItem = localStorage.getItem("SelectedItem");
          const selectedItemData = JSON.parse(selectedItem);
          const dailyTrialID = selectedItemData.id
          console.log("idddddddd", selectedItemData, "dailyTrialID")
          const TrialIndex = localStorage.getItem('TrialIndex')

          const response = await axios.get(
            `${API.GET_DAILY_TRIAL_BY_ID}/${dailyTrialID}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          const generalDetails = response.data.general_details[TrialIndex]
          const furnaceDetails = response.data.furnace_details[TrialIndex]
          console.log(generalDetails, "heh9090")
          const chargeMixDetails = response.data.charge_mix_details[TrialIndex]
          const carbonObject = response.data.carbon[TrialIndex]
          const alloyingAddition = response.data.alloying_addition[TrialIndex]
          const otherParameters = response.data.other_parameters[TrialIndex]

          const ModifiedGeneralDetails = {
            ...generalDetails,
            date: generalDetails.date ? new Date(generalDetails.date) : null
          };

          console.log(ModifiedGeneralDetails, "ModifiedGeneralDetails")

          setGeneralDetails(ModifiedGeneralDetails)
          setFurnaceDetails(furnaceDetails)
          setChargeMixDetails(chargeMixDetails)
          setCarbonObj(carbonObject)
          setAlloyingAddition(alloyingAddition)
          setOtherParameters(otherParameters)
        } catch (error) {
          console.error('Error fetching data:', error)
          setError(error.message)
          // Handle error
        } finally {
          setLoading(false)
        }
      } else {
        setGeneralDetails({})
        setFurnaceDetails({})
        setChargeMixDetails({})
        setCarbonObj({
          id: '',
          met_coke: '',
          fifty_c_percentage: '',
          eightyfive_c_percentage: '',
          final_c_percentage: '',
        })
        setAlloyingAddition({})
        setOtherParameters({})
      }
    }

    if (isOnline) {
      fetchData()
    }
  }, [isEdit, isOnline])

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true);
        localStorage.setItem("onlinestate", 1);

        window.location.reload();
      }
    };

    const handleOffline = () => {
      localStorage.setItem("onlinestate", 0);
      // setIsOnlines(false)
      setIsOnline(false);
      // navigate('/reports')
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isOnline]);

  const dbName = "pcr";
  const customerName = "customers";
  const furnacesName = "furnaces";
  const templateName = "template";
  const segmentName = "segment";
  const datafileName = "datafile";
  const dailytrialName = "daily_trial";
  const nrmName = "nrm_performance";
  const erosionName = "erosion_profile";
  const dataCollectionName = "data_collection";
  const costSheetName = "cost_sheet";
  const LiningName = "lining_patching_no";

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event) => {
        const database = event.target.result;
        database.createObjectStore(customerName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(furnacesName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(templateName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(segmentName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(datafileName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(dailytrialName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(nrmName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(erosionName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(dataCollectionName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(costSheetName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(LiningName, {
          keyPath: "id",
          autoIncrement: true,
        });
      };

      request.onsuccess = (event) => {
        const database = event.target.result;
        const transaction = database.transaction(
          [
            "customers",
            "furnaces",
            "template",
            "segment",
            "datafile",
            "daily_trial",
            "nrm_performance",
            "erosion_profile",
            "data_collection",
            "cost_sheet",
            "lining_patching_no",
          ],
          "readwrite"
        );

        setDb(database);
      };

      request.onerror = (event) => {
        console.error("Error opening IndexedDB", event);
      };
    }
  }, [isOnline]);


  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
      setActiveTab(steps[currentStep - 2]?.id); // Set activeTab based on the previous step
    }
  };

  const handleDraft = async () => {
    const updatedGeneralDetails = {
      ...generalDetails,
      submission_status: "draft",
    };


    try {
      if (isEdit === "new") {
        const datafile_id = localStorage.getItem("datafile_id");
        await insertData(updatedGeneralDetails, datafile_id, "draft");
      } else if (isEdit === "add") {
        const selectedItem = localStorage.getItem("SelectedItem");
        const selectedItemData = JSON.parse(selectedItem);
        const datafile_id = selectedItemData.datafile_id;
        const id = selectedItemData?.id;
        await addData(updatedGeneralDetails, datafile_id, id, "draft");
      } else {
        const selectedItem = localStorage.getItem("SelectedItem");
        const selectedItemData = JSON.parse(selectedItem);
        const datafile_id = selectedItemData.datafile_id;
        const id = selectedItemData?.id;
        const TrialIndex = parseInt(localStorage.getItem("TrialIndex"));
        await updateData(
          updatedGeneralDetails,
          datafile_id,
          id,
          TrialIndex,
          "draft"
        );
      }
    } catch (error) {
      console.error("An error occurred while inserting data:", error.message);
    }
  };

  // const handleNext = () => {
  //   if (currentStep < steps.length) {
  //     setCurrentStep((prev) => prev + 1);
  //     setActiveTab(steps[currentStep]?.id); // Set activeTab based on the next step
  //   }
  // };

  const handleNext = () => {
    const validateGeneralDetails = () => {
      const requiredFields = [
        { key: "furnace_no", label: "Furnace No" },
        { key: "crucible_no", label: "Crucible No" },
        { key: "campaign_no", label: "Campaign No" },
        { key: "lining_patch_no", label: "Lining/Patching No" },
      ];

      const errors = {};
      requiredFields.forEach((field) => {
        if (!generalDetails[field.key]) {
          errors[field.key] = `${field.label} is required.`;
        }
      });

      setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    };

    if (currentStep === 1) {
      // Perform validation for the first tab (General Details)
      if (!validateGeneralDetails()) {
        return; // Stop progression to the next step if validation fails
      }
    }

    // Proceed to the next step if validation passes or no validation is required
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
      setActiveTab(steps[currentStep]?.id); // Set activeTab based on the next step
    }

    // Submit form data if on the last tab
    if (activeTab === 6) {
      submitFormData();
    }
  };


  const insertData = async (updatedGeneralDetails, uploadId, status) => {
    setError(null);
    setLoading(true);
    try {
      if (isOnline) {
        const data = {
          upload_id: uploadId,
          data: [
            {
              general_details: updatedGeneralDetails,
              furnace_details: furnaceDetails,
              charge_mix_details: chargeMixDetails,
              carbon: carbonObj,
              alloying_addition: alloyingAddition,
              other_parameters: otherParameters,
              // other_calculated_varialble: otherCalculatedVariables,
            },
          ],
        };

        console.log(data, "data12");

        const response = await axios.post(API.FORM_INSERT, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const daily_trial_id = response.data.raw[0].id;
        localStorage.setItem("daily_trial_id", daily_trial_id);

        console.log("Data inserted successfully:", response.data.raw[0].id);
      } else {
        const data1 = {
          datafile_id: uploadId,
          created_at: new Date(),
          isDeleted: false,

          general_details: updatedGeneralDetails,
          furnace_details: furnaceDetails,
          charge_mix_details: chargeMixDetails,
          carbon: carbonObj,
          alloying_addition: alloyingAddition,
          other_parameters: otherParameters,
          // other_calculated_varialble: otherCalculatedVariables,
        };
        const transaction = db.transaction(dailytrialName, "readwrite");
        const objectStore = transaction.objectStore(dailytrialName);
        const trialdetailsrequest = objectStore.add(data1);
        trialdetailsrequest.onsuccess = () => {
          console.log(
            "File saved successfully in IndexedDB trialdetails",
            trialdetailsrequest.result
          );
          const daily_trial_id = trialdetailsrequest.result;
          localStorage.setItem("daily_trial_id", daily_trial_id);
        };

        trialdetailsrequest.onerror = () => {
          console.log("Error saving file in IndexedDB");
        };
      }

      if (status === "submitted") {
        setStatusSubmitted(true);
        setStatusDraft(false);
        setStatusApproved(false);
      } else if (status === "approved") {
        setStatusApproved(true);
        setStatusSubmitted(false);
        setStatusDraft(false);
      } else if (status === "draft") {
        setStatusDraft(true);
        setStatusApproved(false);
        setStatusSubmitted(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const addData = async (updatedGeneralDetails, uploadId, id, status) => {
    console.log(updatedGeneralDetails, uploadId, id)
    setError(null);
    setLoading(true);
    try {
      if (isOnline) {
        const res = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        // console.log(res.data, "123res");

        const existingData = res.data;

        const newTrialObj = {
          general_details: updatedGeneralDetails,
          furnace_details: furnaceDetails,
          charge_mix_details: chargeMixDetails,
          carbon: carbonObj,
          alloying_addition: alloyingAddition,
          other_parameters: otherParameters,
          // other_calculated_varialble: otherCalculatedVariables,
        };

        console.log(
          existingData,
          "existingData123",
          "newTrialObj123",
          newTrialObj
        );
        Object.keys(newTrialObj).forEach((key) => {
          if (!existingData[key]) {
            // If the key doesn't exist or its value is null/undefined, initialize it as an empty array
            existingData[key] = [];
          }
          // Now push the newTrialObj data into the array
          existingData[key].push(newTrialObj[key]);
        });

        console.log(newTrialObj, "newTrialObj");
        console.log(existingData);
        console.log(existingData, "updated existingData");

        if (status === "submitted") {
          existingData.general_details.forEach((detail) => {
            detail.submission_status = "submitted";
          });
        } else if (status === "draft") {
          existingData.general_details.forEach((detail) => {
            detail.submission_status = "draft";
          });
        } else {
          existingData.general_details.forEach((detail) => {
            detail.submission_status = "approved";
          });
        }

        const data = {
          upload_id: uploadId,
          data: existingData,
        };

        const response = await axios.put(API.UPDATE_NEW_TRIAL, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        console.log("Data updated successfully:", response.data);

        if (status === "submitted") {
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
        } else if (status === "approved") {
          setStatusApproved(true);
          setStatusSubmitted(false);
          setStatusDraft(false);
        } else if (status === "draft") {
          setStatusDraft(true);
          setStatusApproved(false);
          setStatusSubmitted(false);
        }
      } else {
        // Offline mode: use IndexedDB
        const transaction = db.transaction(dailytrialName, "readonly");
        const objectStore = transaction.objectStore(dailytrialName);

        const id_int = parseInt(id, 10);

        // Define newTrialObj with updated details
        const newTrialObj = {
          general_details: updatedGeneralDetails,
          furnace_details: furnaceDetails,
          charge_mix_details: chargeMixDetails,
          carbon: carbonObj,
          alloying_addition: alloyingAddition,
          other_parameters: otherParameters,
          // other_calculated_varialble: otherCalculatedVariables,
        };

        // Retrieve existing data from IndexedDB
        const getRequest = objectStore.get(id_int);

        getRequest.onsuccess = async () => {
          const existingData = getRequest.result;
          console.log("existingData", existingData, typeof existingData);

          Object.keys(newTrialObj).forEach((key) => {
            if (existingData[key] === undefined) {
              existingData[key] = []; // Initialize as an array if it's undefined
            } else if (!Array.isArray(existingData[key])) {
              // Handle cases where existingData[key] is not an array (if needed)
              // For example, convert it to an array or handle differently
              existingData[key] = [existingData[key]]; // Convert to array
            }

            existingData[key].push(newTrialObj[key]);
          });

          if (Array.isArray(existingData.general_details)) {
            existingData.general_details.forEach((detail) => {
              if (status === "submitted") {
                detail.submission_status = "submitted";
              } else if (status === "draft") {
                detail.submission_status = "draft";
              } else {
                detail.submission_status = "approved";
              }
            });
          } else {
            // If trialdata.general_details is not an array
            existingData.general_details = existingData.general_details || {}; // Ensure it's an object
            if (status === "submitted") {
              existingData.general_details.submission_status = "submitted";
            } else if (status === "draft") {
              existingData.general_details.submission_status = "draft";
            } else {
              existingData.general_details.submission_status = "approved";
            }
          }

          const dataToUpdate = {
            ...existingData,
          };

          const transactionUpdate2 = db.transaction(
            dailytrialName,
            "readwrite"
          );
          const objectStoreUpdate2 =
            transactionUpdate2.objectStore(dailytrialName);

          // Update IndexedDB with the updated data
          const updateRequest = objectStoreUpdate2.put(dataToUpdate);

          updateRequest.onsuccess = () => {
            console.log(
              "Offline Mode - Trial data updated successfully:",
              updateRequest.result
            );
            const daily_trial_id = updateRequest.result;
            localStorage.setItem("daily_trial_id", daily_trial_id);

            if (status === "submitted") {
              setStatusSubmitted(true);
              setStatusDraft(false);
              setStatusApproved(false);
            } else if (status === "approved") {
              setStatusApproved(true);
              setStatusSubmitted(false);
              setStatusDraft(false);
            } else if (status === "draft") {
              setStatusDraft(true);
              setStatusApproved(false);
              setStatusSubmitted(false);
            }
          };

          updateRequest.onerror = () => {
            console.error("Offline Mode - Error updating trial data");
            setError("Error updating trial data in IndexedDB.");
            setLoading(false);
          };
        };

        getRequest.onerror = () => {
          console.error("Offline Mode - Error fetching trial data");
          setError("Error fetching trial data from IndexedDB.");
          setLoading(false);
        };
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateData = async (
    updatedGeneralDetails,
    uploadId,
    id,
    TrialIndex,
    status
  ) => {
    setLoading(true);
    setError(false);
    try {
      if (isOnline) {
        const response = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const data = response.data;

        // Initialize arrays if they are null or undefined
        data.general_details = data.general_details || [];
        data.furnace_details = data.furnace_details || [];
        data.charge_mix_details = data.charge_mix_details || [];
        data.carbon = data.carbon || [];
        data.alloying_addition = data.alloying_addition || [];
        data.other_parameters = data.other_parameters || [];
        data.other_calculated_varialble = data.other_calculated_varialble || [];

        // Update the values based on the index
        data.general_details[TrialIndex] = updatedGeneralDetails;
        data.furnace_details[TrialIndex] = furnaceDetails;
        data.charge_mix_details[TrialIndex] = chargeMixDetails;
        data.carbon[TrialIndex] = carbonObj;
        data.alloying_addition[TrialIndex] = alloyingAddition;
        data.other_parameters[TrialIndex] = otherParameters;
        // data.other_calculated_varialble[TrialIndex] = otherCalculatedVariables

        if (status === "submitted") {
          data.general_details.forEach((detail) => {
            detail.submission_status = "submitted";
          });
        } else if (status === "draft") {
          data.general_details.forEach((detail) => {
            detail.submission_status = "draft";
          });
        } else {
          data.general_details.forEach((detail) => {
            detail.submission_status = "approved";
          });
        }

        const updateResponse = await axios.put(
          `${API.FORM_UPDATE}/${id}`,
          { data: data, id: id },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        console.log("Response:", updateResponse.data);

        if (status === "submitted") {
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
        } else if (status === "approved") {
          setStatusApproved(true);
          setStatusSubmitted(false);
          setStatusDraft(false);
        } else if (status === "draft") {
          setStatusDraft(true);
          setStatusApproved(false);
          setStatusSubmitted(false);
        }
      } else {
        const id = localStorage.getItem("daily_trial_id");
        const TrialIndex = localStorage.getItem("TrialIndex");

        const transaction = db.transaction(dailytrialName, "readwrite");
        const objectStore = transaction.objectStore(dailytrialName);

        const id_int = parseInt(id, 10);
        const getRequest = objectStore.get(id_int);

        getRequest.onsuccess = async () => {
          const trialdata = getRequest.result;

          if (!trialdata) {
            console.error("Trial data not found in IndexedDB.");
            setLoading(false);
            return;
          }

          // Initialize arrays if they are null or undefined
          trialdata.general_details = trialdata.general_details || [];
          trialdata.furnace_details = trialdata.furnace_details || [];
          trialdata.charge_mix_details = trialdata.charge_mix_details || [];
          trialdata.carbon = trialdata.carbon || [];
          trialdata.alloying_addition = trialdata.alloying_addition || [];
          trialdata.other_parameters = trialdata.other_parameters || [];
          trialdata.other_calculated_varialble =
            trialdata.other_calculated_varialble || [];

          // Update the specific row based on TrialIndex
          trialdata.general_details[TrialIndex] = updatedGeneralDetails;
          trialdata.furnace_details[TrialIndex] = furnaceDetails;
          trialdata.charge_mix_details[TrialIndex] = chargeMixDetails;
          trialdata.carbon[TrialIndex] = carbonObj;
          trialdata.alloying_addition[TrialIndex] = alloyingAddition;
          trialdata.other_parameters[TrialIndex] = otherParameters;
          // trialdata.other_calculated_varialble[TrialIndex] =
          //   otherCalculatedVariables

          // Update submission_status for all or specific row
          if (Array.isArray(trialdata.general_details)) {
            trialdata.general_details.forEach((detail) => {
              if (status === "submitted") {
                detail.submission_status = "submitted";
              } else if (status === "draft") {
                detail.submission_status = "draft";
              } else {
                detail.submission_status = "approved";
              }
            });
          } else {
            trialdata.general_details = trialdata.general_details || {};
            if (status === "submitted") {
              trialdata.general_details.submission_status = "submitted";
            } else if (status === "draft") {
              trialdata.general_details.submission_status = "draft";
            } else {
              trialdata.general_details.submission_status = "approved";
            }
          }

          const updateRequest = objectStore.put(trialdata);

          updateRequest.onsuccess = () => {
            console.log(
              "Offline Mode - Trial data updated successfully:",
              updateRequest.result
            );
            const daily_trial_id = updateRequest.result;
            localStorage.setItem("daily_trial_id", daily_trial_id);

            if (status === "submitted") {
              setStatusSubmitted(true);
              setStatusDraft(false);
              setStatusApproved(false);
            } else if (status === "approved") {
              setStatusApproved(true);
              setStatusSubmitted(false);
              setStatusDraft(false);
            } else if (status === "draft") {
              setStatusDraft(true);
              setStatusApproved(false);
              setStatusSubmitted(false);
            }
          };

          updateRequest.onerror = () => {
            console.error(
              "Offline Mode - Error updating trial data in IndexedDB."
            );
            setError("Error updating trial data in IndexedDB.");
            setLoading(false);
          };
        };

        getRequest.onerror = () => {
          console.error(
            "Offline Mode - Error fetching trial data from IndexedDB."
          );
          setError("Error fetching trial data from IndexedDB.");
          setLoading(false);
        };
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const submitFormData = async () => {
    const uploadId = localStorage.getItem("datafile_id");
    let updatedGeneralDetails;

    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      updatedGeneralDetails = {
        ...generalDetails,
        submission_status: "approved",
      };
    } else {
      updatedGeneralDetails = {
        ...generalDetails,
        submission_status: "submitted",
      };
    }
    try {
      if (isEdit === "new") {
        AlertDialog({
          type: "delete",
          title: "Submission",
          text: "Are you sure you want to Submit for an Approval?",
          confirmButtonText: "Yes",
          onConfirm: async () => {
            if (
              currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4
            ) {
              await insertData(
                updatedGeneralDetails,
                uploadId,
                "approved"
              );
            } else {
              await insertData(updatedGeneralDetails, uploadId, "submitted");
            }
          },
        });
      } else if (isEdit === "add") {
        const id = localStorage.getItem("daily_trial_id");
        AlertDialog({
          type: "delete",
          title: "Submission",
          text: "Are you sure you want to Submit for an Approval?",
          confirmButtonText: "Yes",
          onConfirm: async () => {
            if (
              currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4
            ) {
              await addData(updatedGeneralDetails, uploadId, id, "approved");
            } else {
              await addData(updatedGeneralDetails, uploadId, id, "submitted");
            }
          },
        });
      } else {
        const id = localStorage.getItem("daily_trial_id");
        const uploadId = localStorage.getItem("datafile_id");
        console.log(id, "idididid");
        console.log(uploadId, "uploadId");
        const TrialIndex = parseInt(localStorage.getItem("TrialIndex"));
        AlertDialog({
          type: "delete",
          title: "Submission",
          text: "Are you sure you want to Submit for an Approval?",
          confirmButtonText: "Yes",
          onConfirm: async () => {
            if (
              currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4
            ) {
              await updateData(
                updatedGeneralDetails,
                uploadId,
                id,
                TrialIndex,
                "approved"
              );
            } else {
              await updateData(
                updatedGeneralDetails,
                uploadId,
                id,
                TrialIndex,
                "submitted"
              );
            }
          },
        });
      }
    } catch (error) {
      console.error("An error occurred while inserting data:", error);
    }
  };

  const handleOpenUploadModal = () => {
    setModalTitle("Upload New DataFile");
    setIsModalOpen(true);
  };

  const handleOpenAddFormModal = () => {
    setModalTitle("Add Form");
    setIsModalOpen(true);
  };



  const handleTabClick = (tab, step) => {
    setActiveTab(tab);
    setCurrentStep(step);
  };


  return (
    <>
      {statusApproved && <ApproveStatus closeModal={closeModal} />}
      {statusDraft && <DraftStatus closeModal={closeModal} />}
      {statusSubmitted && <SubmittedStatus closeModal={closeModal} />}
      {!statusApproved && !statusDraft && !statusSubmitted && (

        <div className="w-full">
          <div className="flex border-b border-gray-200 dark:border-gray-700 pr-4">
            <ul className="flex -mb-px text-sm font-medium text-center overflow-auto py-2 track-form-list">
              {steps.map((step, index) => (
                <li key={step.id} className="me-2" role="presentation">
                  <div className="flex items-center">
                    <button
                      onClick={() => handleTabClick(step.id, index + 1)}
                      className={`inline-block p-1 whitespace-nowrap font-normal rounded-t-lg ${activeTab === step.id
                        ? "text-custom-orange"
                        : "text-gray-500"
                        }`}
                      type="button"
                      role="tab"
                      aria-controls={step.id}
                      aria-selected={activeTab === step.id}
                    >
                      <span className="flex items-center justify-center">
                        <span
                          className={`flex items-center justify-center w-5 h-5 me-2 text-xs bg-gray-100 border rounded-full shrink-0 dark:border-blue-500 ${activeTab === step.id
                            ? "border-custom-orange text-custom-orange bg-custom-orange-light"
                            : "border-gray-300"
                            }`}
                        >
                          {index + 1}
                        </span>
                        {step.id === 1
                          ? "General"
                          : step.id === 2
                            ? "Furnace"
                            : step.id === 3
                              ? "Charge Mix"
                              : step.id === 4
                                ? "Carbon"
                                : step.id === 5
                                  ? "Alloying"
                                  : "Other"}
                      </span>
                    </button>
                    <img src={rightArrow} alt="arrow" />
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div
            style={{ height: "365px" }}
            className="mt-4 w-full overflow-y-auto pb-4 pr-4"
          >
            {steps[currentStep - 1]?.component}
          </div>

          <div className="flex space-x-4 justify-center bg-white w-full py-4 pr-4">
            <button
              onClick={handlePrevious}
              className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={currentStep === 1}
            >
              Previous
            </button>
            <button
              onClick={handleDraft}
              className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
            >
              Save Draft
            </button>
            <button
              onClick={handleNext}
              className="text-white bg-custom-red w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={activeStep === 6 && !submitFormData}
            >
              {currentStep === 6 ? "Submit" : "Next"}{" "}
            </button>
          </div>
        </div>
      )
      }
    </>
  );
};
export default DailyTrialLayout;
