import React, { useState, useEffect, useRef } from "react";
import UserIcon from "../../assets/images/icons/userIcon.svg";
import MenuIcon from "../../assets/images/icons/MenuIcon.svg";
import IndecLogo from "../../assets/images/indeclogo.png";
import PCRLogo from "../../assets/images/DFLogo.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API from "../../service/API";
const Header = ({ navWidth, isDrawerOpen, toggleDrawer }) => {
  const [notifiOpen, setNotifiOpen] = useState(false);
  const [plantOpen, setPlantOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const storedCustomerDet = JSON.parse(
    localStorage.getItem("selectedCustomer")
  );
  const [customers, setCustomers] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState([]);
  const access_token = localStorage.getItem("accesstoken");
  const [selectedCustomer, setSelectedCustomer] = useState(localStorage.getItem("selectedCustomer")?JSON.parse(localStorage.getItem("selectedCustomer")).id:null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const notificationClick = () => {
    setNotifiOpen(!notifiOpen);
  };

  const profileClick = () => {
    setProfileOpen(!profileOpen);
  };

  useEffect(() => {
    const handleAccessTokenChange = () => {
      const storedAccessToken = localStorage.getItem("selectedCustomer")?JSON.parse(localStorage.getItem("selectedCustomer")).id:null
      if (storedAccessToken !== selectedCustomer) {
        setSelectedCustomer(storedAccessToken);
      }
    };

    window.addEventListener("storage", handleAccessTokenChange);

    const interval = setInterval(handleAccessTokenChange, 1000);

    return () => {
      window.removeEventListener("storage", handleAccessTokenChange);
      clearInterval(interval);
    };
  }, [selectedCustomer]); // Dependency on accessToken
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNotifiOpen(false);
        setPlantOpen(false);
        setProfileOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const customerResponse = response.data;
      console.log("customerResponse", customerResponse);

      if (currentUser.role.role_id === 1) {
        const customerId = currentUser.assign_customer_id[0];
        const customers = customerResponse.filter(
          (item) => item.id === customerId
        );
        setCustomers(customers);

        const selectedCustomer = customers.find(
          (item) => item.id === storedCustomerDet?.id
        );
        if (selectedCustomer) setSelectCustomer(selectedCustomer.id);
      } else if (
        currentUser.role.role_id === 2 ||
        currentUser.role.role_id === 3
      ) {
        const assignCustomerIds = currentUser.assign_customer_id;
        const filteredCustomers = customerResponse.filter((customer) =>
          assignCustomerIds.includes(customer.id)
        );
        setCustomers(filteredCustomers);

        const selectedCustomer = filteredCustomers.find(
          (item) => item.id === storedCustomerDet?.id
        );
        if (selectedCustomer) setSelectCustomer(selectedCustomer.id);
      } else {
        setCustomers(customerResponse);

        const selectedCustomer = customerResponse.find(
          (item) => item.id === storedCustomerDet?.id
        );
        if (selectedCustomer) setSelectCustomer(selectedCustomer.id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  

  const handleCustomerChange = (event) => {
    const selectedCustomerId = event.target.value; 
    console.log(selectedCustomerId, event, "Selected Customer ID"); 
    const selectedCustomerDetails = customers.find(
      (customer) => customer.id === selectedCustomerId
    );

    if (selectedCustomerDetails) {
      setSelectedCustomer(selectedCustomerDetails.id);

      localStorage.setItem(
        "selectedCustomer",
        JSON.stringify(selectedCustomerDetails)
      );

      window.location.reload();
    } else {
      console.error("Selected customer not found in the customers list");
    }
  };


  useEffect(() => {
    fetchCustomers();
  }, []);
  return (
    <nav
      style={{
        width: navWidth,
        transition: "0.3s ease-in-out",
      }}
      className="bg-white border-b border-gray-200 px-4 py-2 dark:bg-gray-800 dark:border-gray-700 fixed right-0 top-0 z-50"
    >
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex justify-start items-center">
          <div className="w-logo px-2 ml-5 lg:ml-4  lg:hidden inline text-center">
            <img
              className="mx-full inline-block"
              src={PCRLogo}
              alt="PCR Logo"
            />
          </div>
          <div className="w-logo px-2 ml-4 lg:ml-4 hidden lg:inline text-center">
            <img
              className="mx-full inline-block"
              src={IndecLogo}
              alt="SG Logo"
            />
          </div>
          <button
            onClick={toggleDrawer}
            aria-controls="drawer-navigation"
            className="menu-icon p-2 mr-2 w-10 h-10 text-center text-gray-600 cursor-pointer  dark:focus:bg-gray-700  dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {isDrawerOpen ? (
              // Icon for "close"
              <svg
                className="w-4 h-4 ms-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            ) : (
              <img src={MenuIcon} />
            )}
            <span className="sr-only">Toggle sidebar</span>
          </button>
        </div>

        <div className="flex items-center lg:order-2 relative">
          <select
            id="customer"
            name="customer"
            value={selectedCustomer || ""}
            onChange={handleCustomerChange}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select Customer</option>
            {customers.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.organization_name}
              </option>
            ))}
          </select>

          <div className=" relative">
          <button
            type="button"
            className="flex p-2 rounded-lg text-sm hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
            id="user-menu-button"
            onClick={profileClick}
            aria-expanded="false"
            data-dropdown-toggle="dropdown"
          >
            <span className="sr-only">Open user menu</span>
            <img src={UserIcon} alt="Notification" />
          </button>
          <div
            ref={dropdownRef}
            className={`${profileOpen ? "" : "hidden"} absolute right-4 top-12 z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl`}
            id="dropdown"
          >
            <div className="py-3 px-4">
              <span className="block text-sm font-semibold text-gray-900 dark:text-white">
                {currentUser.firstname} {currentUser.lastname}{" "} <br/>
                <span className="block text-sm text-gray-500 dark:text-white">{currentUser.role.name}</span>
              </span>
              <span className="block text-sm text-gray-900 truncate dark:text-white">
                {currentUser.email}
              </span>
            </div>
            <ul
              className="py-1 text-gray-700 dark:text-gray-300"
              aria-labelledby="dropdown"
            >
              <li>
                <a
                  href="#"
                  className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                >
                  My profile
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                >
                  Account settings
                </a>
              </li>
            </ul>
            <ul
              className="py-1 text-gray-700 dark:text-gray-300"
              aria-labelledby="dropdown"
            >
              <li>
                <a
                  href="#"
                  onClick={handleLogout}
                  className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Sign out
                </a>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
