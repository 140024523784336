import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { increased_productivity_nrm, trial_name, increased_productivity, materialType } from "../../../recoil/atom";
import {
  maxNoOfHeatsSelector,
  noOfSinteringSelector,
  totDownTimeSelector,
  totDownTimeDueToFceSelector,
  totTimeAvailableProdSelector,
  actualNoOfHeatsSelector,
  actualProdInMonthSelector,
  maxNoOfHeatsNRMSelector,
  noOfSinteringNRMSelector,
  totDownTimeNRMSelector,
  totDownTimeDueToFceNRMSelector,
  totTimeAvailableProdNRMSelector,
  actualNoOfHeatsNRMSelector,
  actualProdInMonthNRMSelector,
  prodIncreaseSelector,
  perOfProdDueToNRMSelector,
  costBenefitsSelector,
  savingDueToIncProdSelector,
} from '../../../recoil/selector'
import Helper from "../../../service/Helper";
const Productivity = () => {
  const [increasedProductivity, setIncreasedProductivity] = useRecoilState(
    increased_productivity
  )
  const [increasedProductivityNRM, setIncreasedProductivityNRM] =
    useRecoilState(increased_productivity_nrm)
  const [trialName] = useRecoilState(trial_name)
  const [type, setType] = useRecoilState(materialType)

  const maxNoOfHeats = useRecoilValue(maxNoOfHeatsSelector)
  console.log('Max No. of Heats:', maxNoOfHeats)

  const noOfSintering = useRecoilValue(noOfSinteringSelector)
  console.log('No. of Sintering:', noOfSintering)

  const totDownTime = useRecoilValue(totDownTimeSelector)
  console.log('Total Down Time:', totDownTime)

  const totDownTimeDueToFce = useRecoilValue(totDownTimeDueToFceSelector)
  console.log('Total Down Time Due to FCE:', totDownTimeDueToFce)

  const totTimeAvailableProd = useRecoilValue(totTimeAvailableProdSelector)
  console.log('Total Time Available for Production:', totTimeAvailableProd)

  const actualNoOfHeats = useRecoilValue(actualNoOfHeatsSelector)
  console.log('Actual No. of Heats:', actualNoOfHeats)

  const actualProdInMonth = useRecoilValue(actualProdInMonthSelector)
  console.log('Actual Production in a Month:', actualProdInMonth)

  // NRM

  const maxNoOfHeatsNRM = useRecoilValue(maxNoOfHeatsNRMSelector)
  console.log('Max No. of Heats NRM:', maxNoOfHeatsNRM)

  const noOfSinteringNRM = useRecoilValue(noOfSinteringNRMSelector)
  console.log('No. of Sintering NRM:', noOfSinteringNRM)

  const totDownTimeNRM = useRecoilValue(totDownTimeNRMSelector)
  console.log('Total Down Time NRM:', totDownTimeNRM)

  const totDownTimeDueToFceNRM = useRecoilValue(totDownTimeDueToFceNRMSelector)
  console.log('Total Down Time Due to FCE NRM:', totDownTimeDueToFceNRM)

  const totTimeAvailableProdNRM = useRecoilValue(
    totTimeAvailableProdNRMSelector
  )
  console.log(
    'Total Time Available for Production NRM:',
    totTimeAvailableProdNRM
  )

  const actualNoOfHeatsNRM = useRecoilValue(actualNoOfHeatsNRMSelector)
  console.log('Actual No. of Heats NRM:', actualNoOfHeatsNRM)

  const actualProdInMonthNRM = useRecoilValue(actualProdInMonthNRMSelector)
  console.log('Actual Production in a Month NRM:', actualProdInMonthNRM)

  const prodIncrease = useRecoilValue(prodIncreaseSelector)
  console.log('Production Increase:', prodIncrease)

  const perOfProdDueToNRM = useRecoilValue(perOfProdDueToNRMSelector)
  console.log(
    'Percentage of Production Increase Due to NRM:',
    perOfProdDueToNRM
  )

  const costBenefits = useRecoilValue(costBenefitsSelector)
  console.log('Cost Benefits:', costBenefits)

  const savingDueToIncProd = useRecoilValue(savingDueToIncProdSelector)
  console.log('Savings Due to Increased Production:', savingDueToIncProd)

  const handleIncreasedProductivity = (name, value) => {
    setIncreasedProductivity((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleIncreasedProductivityNRM = (name, value) => {
    setIncreasedProductivityNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const totalAvailableTime = 24 * 60 * 30
  
  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };


  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S3 : Savings due to increased productivity
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="avg_heat_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Heat time (with / without hot heel for NRM) - (Minute / Heat)
          </label>
          <input
            type="text"
            id="avg_heat_time"
            name="avg_heat_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter time"
            value={increasedProductivity.avg_heat_time}
            onChange={(event) =>
              handleIncreasedProductivity('avg_heat_time', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Available time in a month (30 days) - (Minute)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter value"
            value={totalAvailableTime}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Maximum No. of Heats in a month - <br />
            (No of Heat)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter value"
            value={maxNoOfHeats ? maxNoOfHeats : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No. of Sintering Heats in a Month - <br />
            (No of Sintering heat)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter value"
            value={noOfSintering}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="extra_time_sintering"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Extra time in sintering heat in min with Main Panel (Minute)
          </label>
          <input
            type="text"
            id="extra_time_sintering"
            name="extra_time_sintering"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter time"
            value={increasedProductivity.extra_time_sintering}
            onChange={(event) =>
              handleIncreasedProductivity(
                'extra_time_sintering',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="totDownTime"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Down time in a month due to sintering (Minute)
          </label>
          <input
            type="text"
            id="totDownTime"
            name="totDownTime"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={totDownTime ? totDownTime : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="avg_fce_checking_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Fce Checking Time <br />
            (Minute)
          </label>
          <input
            type="text"
            id="avg_fce_checking_time"
            name="avg_fce_checking_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Time"
            value={increasedProductivity.avg_fce_checking_time}
            onChange={(event) =>
              handleIncreasedProductivity(
                'avg_fce_checking_time',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="frequency_of_furnace"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Frequency of furnace Checking as percentage (%)
          </label>
          <input
            type="text"
            id="frequency_of_furnace"
            name="frequency_of_furnace"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter percentage"
            value={increasedProductivity.frequency_of_furnace}
            onChange={(event) =>
              handleIncreasedProductivity(
                'frequency_of_furnace',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full">
          <label
            htmlFor="totDownTimeDueToFce"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total down time in a month due to Fce Checking time (Minute)
          </label>
          <input
            type="text"
            id="totDownTimeDueToFce"
            name="totDownTimeDueToFce"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Total down time"
            value={totDownTimeDueToFce ? totDownTimeDueToFce : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customtotal_down_time_change_overer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total down time in a month due to Change over (Minute)
          </label>
          <input
            type="text"
            id="total_down_time_change_over"
            name="total_down_time_change_over"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Total down time"
            value={increasedProductivity.total_down_time_change_over}
            onChange={(event) =>
              handleIncreasedProductivity(
                'total_down_time_change_over',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customtotal_down_time_change_overer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Fce Repair Time in a month (Minute)
          </label>
          <input
            type="text"
            id="avg_fce_repairing_time"
            name="avg_fce_repairing_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Total down time"
            value={increasedProductivity.avg_fce_repairing_time}
            onChange={(event) =>
              handleIncreasedProductivity(
                'avg_fce_repairing_time',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        {/* <div className="w-1/2">
          <label
            htmlFor="totTimeAvailableProd"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Fce Repair Time in a month (Minute)
          </label>
          <input
            type="text"
            id="totTimeAvailableProd"
            name="totTimeAvailableProd"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Time"
            value={totTimeAvailableProd ? totTimeAvailableProd : '0.00'}
            onChange={() => { }}
          />
        </div> */}
        <div className="w-1/2">
          <label
            htmlFor="totTimeAvailableProd"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total time available for Production in a Month (Minute)
          </label>
          <input
            type="text"
            id="totTimeAvailableProd"
            name="totTimeAvailableProd"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Time"
            value={totTimeAvailableProd ? totTimeAvailableProd : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="actualNoOfHeats"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Actual No.of heats in a month (No of heat)
          </label>
          <input
            type="text"
            id="actualNoOfHeats"
            name="actualNoOfHeats"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Time"
            value={actualNoOfHeats ? actualNoOfHeats : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="actualProdInMonth"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Actual Production in a month (MT)
          </label>
          <input
            type="text"
            id="actualProdInMonth"
            name="actualProdInMonth"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter value"
            value={actualProdInMonth ? actualProdInMonth : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Production increase due to NRM (MT / Month)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter value"
            value="NA"
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            % production increase in a month <br />(%)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Percentage"
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Profit Margin per ton of steel (dia)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Value"
            value="NA"
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost benefit due to NRM in a month (dia)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Value"
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Saving due to increased productivity (dia /MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Saving"
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>

      {/* NRM */}
      <h2 className="text-l font-medium text-gray-800 mb-4 mt-4 uppercase">
        NRM
      </h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="avg_heat_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Heat time (with / without hot heel for NRM) - (Minute / Heat)
          </label>
          <input
            type="text"
            id="avg_heat_time"
            name="avg_heat_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={increasedProductivityNRM.avg_heat_time}
            onChange={(event) =>
              handleIncreasedProductivityNRM('avg_heat_time', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Available time in a month (30 days) - (Minute)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={totalAvailableTime}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Maximum No. of Heats in a month - <br />
            (No of Heat)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={maxNoOfHeatsNRM ? maxNoOfHeatsNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No. of Sintering Heats in a Month <br />
            (No of Sintering heat)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={noOfSinteringNRM ? noOfSinteringNRM : "0.00"}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="extra_time_sintering"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Extra time in sintering heat in min with Main Panel (Minute)
          </label>
          <input
            type="text"
            id="extra_time_sintering"
            name="extra_time_sintering"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={increasedProductivityNRM.extra_time_sintering}
            onChange={(event) =>
              handleIncreasedProductivityNRM(
                'extra_time_sintering',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="totDownTime"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Down time in a month due to sintering (Minute)
          </label>
          <input
            type="text"
            id="totDownTime"
            name="totDownTime"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={totDownTimeNRM ? totDownTimeNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="avg_fce_checking_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Fce Checking Time <br />
            (Minute)
          </label>
          <input
            type="text"
            id="avg_fce_checking_time"
            name="avg_fce_checking_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={increasedProductivityNRM.avg_fce_checking_time}
            onChange={(event) =>
              handleIncreasedProductivityNRM(
                'avg_fce_checking_time',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="frequency_of_furnace"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Frequency of furnace Checking as percentage (%)
          </label>
          <input
            type="text"
            id="frequency_of_furnace"
            name="frequency_of_furnace"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={increasedProductivityNRM.frequency_of_furnace}
            onChange={(event) =>
              handleIncreasedProductivityNRM(
                'frequency_of_furnace',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full">
          <label
            htmlFor="totDownTimeDueToFce"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total down time in a month due to Fce Checking time (Minute)
          </label>
          <input
            type="text"
            id="totDownTimeDueToFce"
            name="totDownTimeDueToFce"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={totDownTimeDueToFceNRM ? totDownTimeDueToFceNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customtotal_down_time_change_overer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total down time in a month due to Change over (Minute)
          </label>
          <input
            type="text"
            id="total_down_time_change_over"
            name="total_down_time_change_over"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={increasedProductivityNRM.total_down_time_change_over}
            onChange={(event) =>
              handleIncreasedProductivityNRM(
                'total_down_time_change_over',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="avg_fce_repairing_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Fce Repair Time in a month (Minute)
          </label>
          <input
            type="text"
            id="avg_fce_repairing_time"
            name="avg_fce_repairing_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={increasedProductivityNRM.avg_fce_repairing_time}
            onChange={(event) =>
              handleIncreasedProductivityNRM(
                'avg_fce_repairing_time',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="totTimeAvailableProd"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total time available for Production in a Month (Minute)
          </label>
          <input
            type="text"
            id="totTimeAvailableProd"
            name="totTimeAvailableProd"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={totTimeAvailableProdNRM ? totTimeAvailableProdNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="actualNoOfHeats"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Actual No.of heats in a month (No of heat)
          </label>
          <input
            type="text"
            id="actualNoOfHeats"
            name="actualNoOfHeats"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={actualNoOfHeatsNRM ? actualNoOfHeatsNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="actualProdInMonth"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Actual Production in a month (MT)
          </label>
          <input
            type="text"
            id="actualProdInMonth"
            name="actualProdInMonth"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={actualProdInMonthNRM ? actualProdInMonthNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Production increase due to NRM (MT / Month)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={prodIncrease ? prodIncrease : "0.00"}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            % production increase in a month <br />(%)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={perOfProdDueToNRM ? perOfProdDueToNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Profit Margin per ton of steel (dia)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={increasedProductivityNRM.profit_margin}
            onChange={(event) =>
              handleIncreasedProductivityNRM('profit_margin', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost benefit due to NRM in a month (dia)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costBenefits ? costBenefits : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Saving due to increased productivity (dia /MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={savingDueToIncProd ? savingDueToIncProd : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
    </>
  );
};

export default Productivity;
