import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  height_bc_0,
  height_bc_10,
  height_bc_20,
  height_bc_30,
  height_bc_40,
  height_bc_50,
  height_bc_60,
  height_bc_70,
  height_bc_80,
  height_bc_90,
  height_bc_100,
  trial_name
} from '../../recoil/atom'
import {
  beforeChipping_0,
  beforeChipping_10,
  beforeChipping_20,
  beforeChipping_30,
  beforeChipping_40,
  beforeChipping_50,
  beforeChipping_60,
  beforeChipping_70,
  beforeChipping_80,
  beforeChipping_90,
  beforeChipping_100,
} from '../../recoil/selector'

const BeforeChipping = () => {
  const [height0, setHeight0] = useRecoilState(height_bc_0)
  const [height10, setHeight10] = useRecoilState(height_bc_10)
  const [height20, setHeight20] = useRecoilState(height_bc_20)
  const [height30, setHeight30] = useRecoilState(height_bc_30)
  const [height40, setHeight40] = useRecoilState(height_bc_40)
  const [height50, setHeight50] = useRecoilState(height_bc_50)
  const [height60, setHeight60] = useRecoilState(height_bc_60)
  const [height70, setHeight70] = useRecoilState(height_bc_70)
  const [height80, setHeight80] = useRecoilState(height_bc_80)
  const [height90, setHeight90] = useRecoilState(height_bc_90)
  const [height100, setHeight100] = useRecoilState(height_bc_100)

  // Calculated values
  const bc_lot_0 = useRecoilValue(beforeChipping_0)
  const bc_lot_10 = useRecoilValue(beforeChipping_10)
  const bc_lot_20 = useRecoilValue(beforeChipping_20)
  const bc_lot_30 = useRecoilValue(beforeChipping_30)
  const bc_lot_40 = useRecoilValue(beforeChipping_40)
  const bc_lot_50 = useRecoilValue(beforeChipping_50)
  const bc_lot_60 = useRecoilValue(beforeChipping_60)
  const bc_lot_70 = useRecoilValue(beforeChipping_70)
  const bc_lot_80 = useRecoilValue(beforeChipping_80)
  const bc_lot_90 = useRecoilValue(beforeChipping_90)
  const bc_lot_100 = useRecoilValue(beforeChipping_100)

  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewErosionProfile'

  const handleChange = (e, setState) => {
    const { name, value } = e.target
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const renderSection = (title, state, setState, bcSpoutBack, bcLeftRight) => (
    <div>
      <div style={{ marginTop: '10px' }}>
        <div className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {title}
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="tot_Consumpt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Coil Coat Diameter - <br /> (inch)
          </label>
          <input
            type="text"
            name="coil_coat_diameter"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Coil Coat Diameter"
            value={state.coil_coat_diameter || ""}
            onChange={(e) => handleChange(e, setState)}
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="tot_Consumpt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            BC Left - Right erosion - (Inch)
          </label>
          <input
            type="text"
            name="bc_left_right_erosion"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter BC Left - Right"
            value={state.bc_left_right_erosion || ""}
            onChange={(e) => handleChange(e, setState)}
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="tot_Consumpt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            BC Spout - back erosion - (inch)
          </label>
          <input
            type="text"
            name="bc_spout_back_erosion"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter BC Spout - back"
            value={state.bc_spout_back_erosion || ""}
            onChange={(e) => handleChange(e, setState)}
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div>
        <div style={{ marginTop: '10px' }}>
          <div className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Left over thickness (LOT) before chipping(BC)
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full mb-4">
            <label
              htmlFor="tot_Consumpt"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              BC LOT: Left - Right (inch)
            </label>
            <input
              type="text"
              name="bc_left_right_0"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter BC LOT: Left - Right"
              value={bcLeftRight || ""}
              disabled
            />
          </div>
          <div className="w-full mb-4">
            <label
              htmlFor="tot_Consumpt"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              BC LOT: Spout - Back (Inch)
            </label>
            <input
              type="text"
              name="bc_spout_back_0"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter BC LOT: Spout - Back"
              value={bcSpoutBack || ""}
              disabled
            />
          </div>

        </div>
      </div>
    </div>
  )

  return (
    <div style={{ width: '100%' }}>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Erosion before Chipping
      </h2>
      {renderSection(
        'Erosion Before Chipping - Height 0',
        height0,
        setHeight0,
        bc_lot_0.spout_back_0,
        bc_lot_0.left_right_0
      )}
      {renderSection(
        'Erosion Before Chipping - Height 10',
        height10,
        setHeight10,
        bc_lot_10.spout_back_10,
        bc_lot_10.left_right_10
      )}
      {renderSection(
        'Erosion Before Chipping - Height 20',
        height20,
        setHeight20,
        bc_lot_20.spout_back_20,
        bc_lot_20.left_right_20
      )}
      {renderSection(
        'Erosion Before Chipping - Height 30',
        height30,
        setHeight30,
        bc_lot_30.spout_back_30,
        bc_lot_30.left_right_30
      )}
      {renderSection(
        'Erosion Before Chipping - Height 40',
        height40,
        setHeight40,
        bc_lot_40.spout_back_40,
        bc_lot_40.left_right_40
      )}
      {renderSection(
        'Erosion Before Chipping - Height 50',
        height50,
        setHeight50,
        bc_lot_50.spout_back_50,
        bc_lot_50.left_right_50
      )}
      {renderSection(
        'Erosion Before Chipping - Height 60',
        height60,
        setHeight60,
        bc_lot_60.spout_back_60,
        bc_lot_60.left_right_60
      )}
      {renderSection(
        'Erosion Before Chipping - Height 70',
        height70,
        setHeight70,
        bc_lot_70.spout_back_70,
        bc_lot_70.left_right_70
      )}
      {renderSection(
        'Erosion Before Chipping - Height 80',
        height80,
        setHeight80,
        bc_lot_80.spout_back_80,
        bc_lot_80.left_right_80
      )}
      {renderSection(
        'Erosion Before Chipping - Height 90',
        height90,
        setHeight90,
        bc_lot_90.spout_back_90,
        bc_lot_90.left_right_90
      )}
      {renderSection(
        'Erosion Before Chipping - Height 100',
        height100,
        setHeight100,
        bc_lot_100.spout_back_100,
        bc_lot_100.left_right_100
      )}
    </div>
  )
}

export default BeforeChipping
