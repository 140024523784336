import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { customer_details, trial_name } from '../../../recoil/atom';

const GeneralDetails = () => {

  const [custDet, setCustDet] = useRecoilState(customer_details)
  const [trialName] = useRecoilState(trial_name)
  const [params, setParams] = useState('')
  const currencyCodes = require('currency-codes')
  const isReadOnly = trialName === 'viewCostSheet'


  // useEffect(() => {
  //   // const formattedCountryOptions = formatCountryOptions()
  //   setCountryOptions(formattedCountryOptions)
  //   setSelectedCountry(formattedCountryOptions[0])
  // }, [])

  // const formatCountryOptions = () => {
  //   const countries = getCountryDataList()
  //   const countryOptions = []
  //   for (const code in countries) {
  //     const country = countries[code]
  //     countryOptions.push({ id: code, name: country.name })
  //   }
  //   return countryOptions
  // }

  // const handleCountryChange = (selectedOption) => {
  //   console.log()
  //   const selectedCountryData = getCountryDataList()[selectedOption.id]
  //   if (selectedCountryData) {
  //     const currency = selectedCountryData.currency[0]
  //     const updatedCustDet = {
  //       ...custDet,
  //       country: selectedCountryData.name,
  //       currency: currency,
  //     }
  //     setCustDet(updatedCustDet)
  //   }
  // }

  const handleChange = (event) => {
    const { name, value } = event.target
    setCustDet((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleParameterChange = (selectedParameter) => {
    if (selectedParameter) {
      const parameterName = selectedParameter.name
      setCustDet((prevState) => ({
        ...prevState,
        parameter: parameterName,
      }))
      setParams(parameterName)
    }
  }

  useEffect(() => {
    const data = {
      parameter: custDet.parameter,
      customerName: custDet.customer_name,
      country: custDet.country,
      currency: custDet.currency,
    }
    localStorage.setItem('customerDetails', JSON.stringify(data))
  }, [custDet])

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">General Details</h2>

      <div className="flex space-x-4 mt-4">
        {/* <div className="w-1/2">
          <label htmlFor="furnace_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Parameter
          </label>
          <select
            id="furnace_no"
            name="furnace_no"
            // value={formData.furnace_no}
            onChange={handleChange}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a furnace</option>
          </select>
        </div> */}

        <div className="w-1/2">
          <label
            htmlFor="customer_name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Customer Name
          </label>
          <input
            type="text"
            id="customer_name"
            name="customer_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Customer Name"
            value={custDet.customer_name}
            onChange={handleChange}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="plant_running_hr"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Plant running Hr.
          </label>
          <input
            type="text"
            id="plant_running_hr"
            name="plant_running_hr"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={custDet.plant_running_hr}
            onChange={handleChange}
          />
        </div>

      </div>


      <div className="flex space-x-4 mt-4">
      <div className="w-1/2">
          <label
            htmlFor="country"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
           country
          </label>
          <input
            type="text"
            id="country"
            name="country"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter country"
            value={custDet.country}
            onChange={handleChange}
          />
        </div>

        {/* Crucible No Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="currency"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
             currency
          </label>
          <input
            type="text"
            id="currency"
            name="currency"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={custDet.currency}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default GeneralDetails;
