import React, { useState, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import AlertDialog from "../Common/AlertDialog";
import ConsumptionLayout from "./ConsumptionDetails/ConsumptionLayout";
import SaveDetailsLayout from "./SaveDetails/SaveDetailsLayout";
import CostCalculation from "./CostCalculation/CostCalculation";
import ApproveStatus from "../Status/ApproveStatus";
import DraftStatus from "../Status/DraftStatus";
import SubmittedStatus from "../Status/SubmittedStatus";
import axios from "axios";
import {
  editSavingDetails,
  trial_name,
  cost_calculation_nrm,
  cost_calculation,
  savings_metallurgical_benefit_nrm,
  savings_metallurgical_benefit,
  savings_tangible_benefit_nrm,
  savings_tangible_benefit,
  savings_alloy_nrm,
  savings_alloy,
  yield_improvement_nrm,
  yield_improvement,
  savings_energy_nrm,
  savings_energy,
  increased_productivity_nrm,
  increased_productivity,
  former_cost,
  former_cost_nrm,
  labor_cost_nrm,
  customer_details,
  cost_sheet_furnace,
  cost_sheet_furnace_nrm,
  new_lining_details,
  new_lining_details_nrm,
  side_lining_details,
  side_lining_details_nrm,
  patching_details,
  patching_details_nrm,
  repairing_details,
  repairing_details_nrm,
  labor_cost,
  sintering_heats,
  sintering_heats_nrm,
  materialType
} from '../../recoil/atom'

import {
  costCalculation,
  costCalculationNRM,
  laborCostCampaignSelector,
  laborCostPerTonSelector,
  laborCostCampaignNRMSelector,
  laborCostPerTonNRMSelector,
  savingInLaborCostNRMSelector,
  formerCostCampaignSelector,
  formerCostPerTonSelector,
  formerCostCampaignNRMSelector,
  formerCostPerTonNRMSelector,
  savingInFormerCostSelector,
  maxNoOfHeatsSelector,
  noOfSinteringSelector,
  totDownTimeSelector,
  totDownTimeDueToFceSelector,
  totTimeAvailableProdSelector,
  actualNoOfHeatsSelector,
  actualProdInMonthSelector,
  maxNoOfHeatsNRMSelector,
  noOfSinteringNRMSelector,
  totDownTimeNRMSelector,
  totDownTimeDueToFceNRMSelector,
  totTimeAvailableProdNRMSelector,
  actualNoOfHeatsNRMSelector,
  actualProdInMonthNRMSelector,
  perOfProdDueToNRMSelector,
  savingDueToIncProdSelector,
  prodIncreaseSelector,
  costBenefitsSelector,
  costSavingsDueToPowerSelector,
  costSavingsDueToYieldImprovementSelector,
  savingsDueToReductionSelector,
  otherTangibleBenefitsSelector,
  chargedSinteringPerMonthSelector,
  costSinteringHeatPerMonthSelector,
  costOfReplacingWithDRISelector,
  costOfchargedSinteringHeatPerMTSelector,
  chargedSinteringPerMonthNRMSelector,
  costSinteringHeatPerMonthNRMSelector,
  costOfReplacingWithDRINRMSelector,
  costOfchargedSinteringHeatPerMTNRMSelector,
} from '../../recoil/selector'
import API from "../../service/API";

const CostSheetLayout = ({ closeModal }) => {
  const [activeTab, setActiveTab] = useState("ConsumptionDetailsTab");
  const [activeStep, setActiveStep] = useState(0)
  const [currentStep, setCurrentStep] = useState(1);
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(cost_sheet_furnace)
  const [furnaceDetailsNRM, setFurnaceDetailsNRM] = useRecoilState(
    cost_sheet_furnace_nrm
  )
  const [triggerEvent, setTriggerEvent] = useState(false)
  const [statusDraft, setStatusDraft] = useState(false);
  const [statusSubmitted, setStatusSubmitted] = useState(false);
  const [statusApproved, setStatusApproved] = useState(false);
  const [newLining, setNewLining] = useRecoilState(new_lining_details)
  const [newLiningNrm, setNewLiningNrm] = useRecoilState(new_lining_details_nrm)
  const [sideLining, setSideLining] = useRecoilState(side_lining_details)
  const [sideLiningNrm, setSideLiningNrm] = useRecoilState(
    side_lining_details_nrm
  )
  const [patchingDetails, setPatchingDetails] = useRecoilState(patching_details)
  const [patchingDetailsNrm, setPatchingDetailsNrm] =
    useRecoilState(patching_details_nrm)
  const [repairingDetails, setRepairingDetails] =
    useRecoilState(repairing_details)
  const [repairingDetailsNrm, setRepairingDetailsNrm] = useRecoilState(
    repairing_details_nrm
  )
  const [type, setType] = useRecoilState(materialType)
  const [custDet, setCustDet] = useRecoilState(customer_details)
  console.log(custDet, 'custDet123')
  const CostSavingRef = useRef()

  // labor cost

  const laborCostCampaign = useRecoilValue(laborCostCampaignSelector)
  const laborCostPerTon = useRecoilValue(laborCostPerTonSelector)
  const laborCostCampaignNRM = useRecoilValue(laborCostCampaignNRMSelector)
  const laborCostPerTonNRM = useRecoilValue(laborCostPerTonNRMSelector)
  const SavingInLaborCostNRM = useRecoilValue(savingInLaborCostNRMSelector)

  // former cost
  const FormerCostCampaign = useRecoilValue(formerCostCampaignSelector)
  const formerCostPerTon = useRecoilValue(formerCostPerTonSelector)
  const FormerCostCampaignNRM = useRecoilValue(formerCostCampaignNRMSelector)
  const formerCostPerTonNRM = useRecoilValue(formerCostPerTonNRMSelector)
  const savingInFormerCost = useRecoilValue(savingInFormerCostSelector)
  // increased productivity

  const maxNoOfHeats = useRecoilValue(maxNoOfHeatsSelector)
  console.log('Max No. of Heats:', maxNoOfHeats)

  const noOfSintering = useRecoilValue(noOfSinteringSelector)
  console.log('No. of Sintering:', noOfSintering)

  const totDownTime = useRecoilValue(totDownTimeSelector)
  console.log('Total Down Time:', totDownTime)

  const totDownTimeDueToFce = useRecoilValue(totDownTimeDueToFceSelector)
  console.log('Total Down Time Due to FCE:', totDownTimeDueToFce)

  const totTimeAvailableProd = useRecoilValue(totTimeAvailableProdSelector)
  console.log('Total Time Available for Production:', totTimeAvailableProd)

  const actualNoOfHeats = useRecoilValue(actualNoOfHeatsSelector)
  console.log('Actual No. of Heats:', actualNoOfHeats)

  const actualProdInMonth = useRecoilValue(actualProdInMonthSelector)
  console.log('Actual Production in a Month:', actualProdInMonth)

  // NRM

  const maxNoOfHeatsNRM = useRecoilValue(maxNoOfHeatsNRMSelector)
  console.log('Max No. of Heats NRM:', maxNoOfHeatsNRM)

  const noOfSinteringNRM = useRecoilValue(noOfSinteringNRMSelector)
  console.log('No. of Sintering NRM:', noOfSinteringNRM)

  const totDownTimeNRM = useRecoilValue(totDownTimeNRMSelector)
  console.log('Total Down Time NRM:', totDownTimeNRM)

  const totDownTimeDueToFceNRM = useRecoilValue(totDownTimeDueToFceNRMSelector)
  console.log('Total Down Time Due to FCE NRM:', totDownTimeDueToFceNRM)

  const totTimeAvailableProdNRM = useRecoilValue(
    totTimeAvailableProdNRMSelector
  )
  console.log(
    'Total Time Available for Production NRM:',
    totTimeAvailableProdNRM
  )

  const actualNoOfHeatsNRM = useRecoilValue(actualNoOfHeatsNRMSelector)
  console.log('Actual No. of Heats NRM:', actualNoOfHeatsNRM)

  const actualProdInMonthNRM = useRecoilValue(actualProdInMonthNRMSelector)
  console.log('Actual Production in a Month NRM:', actualProdInMonthNRM)

  const prodIncrease = useRecoilValue(prodIncreaseSelector)
  console.log('Production Increase:', prodIncrease)

  const perOfProdDueToNRM = useRecoilValue(perOfProdDueToNRMSelector)
  console.log(
    'Percentage of Production Increase Due to NRM:',
    perOfProdDueToNRM
  )

  const costBenefits = useRecoilValue(costBenefitsSelector)
  console.log('Cost Benefits:', costBenefits)

  const savingDueToIncProd = useRecoilValue(savingDueToIncProdSelector)
  console.log('Savings Due to Increased Production:', savingDueToIncProd)

  const costSavingsDutToPower = useRecoilValue(costSavingsDueToPowerSelector)
  const costSavingsDueToPower = useRecoilValue(
    costSavingsDueToYieldImprovementSelector
  )
  const savingsDueToReduction = useRecoilValue(savingsDueToReductionSelector)
  const otherTangibleBenefits = useRecoilValue(otherTangibleBenefitsSelector)

  // savings details

  const [laborCost, setLaborCost] = useRecoilState(labor_cost)
  const [laborCostNRM, setLaborCostNRM] = useRecoilState(labor_cost_nrm)
  const [formerCost, setFormerCost] = useRecoilState(former_cost)
  const [formerCostNRM, setFormerCostNRM] = useRecoilState(former_cost_nrm)
  const [increasedProductivity, setIncreasedProductivity] = useRecoilState(
    increased_productivity
  )
  const [increasedProductivityNRM, setIncreasedProductivityNRM] =
    useRecoilState(increased_productivity_nrm)
  const [energy, setEnergy] = useRecoilState(savings_energy)
  const [energyNRM, setEnergyNRM] = useRecoilState(savings_energy_nrm)
  const [yieldImprovement, setYieldImprovement] =
    useRecoilState(yield_improvement)
  const [yieldImprovementNRM, setYieldImprovementNRM] = useRecoilState(
    yield_improvement_nrm
  )
  const [alloy, setAlloy] = useRecoilState(savings_alloy)
  const [alloyNRM, setAlloyNRM] = useRecoilState(savings_alloy_nrm)
  const [tangibleBenefits, setTangibleBenefits] = useRecoilState(
    savings_tangible_benefit
  )
  const [tangibleBenefitsNRM, setTangibleBenefitsNRM] = useRecoilState(
    savings_tangible_benefit_nrm
  )
  const [, setMetallurgicalBenefits] = useRecoilState(
    savings_metallurgical_benefit
  )
  const [metallurgicalBenefitsNRM, setMetallurgicalBenefitsNRM] =
    useRecoilState(savings_metallurgical_benefit_nrm)
  const [sinteringHeats, setSinteringHeats] = useRecoilState(sintering_heats)
  const [sinteringHeatsNRM, setSinteringHeatsNRM] =
    useRecoilState(sintering_heats_nrm)

  const [CostSheet, setCostSheet] = useState([])
  const [trialName,] = useRecoilState(trial_name)
  const currentUser = JSON.parse(localStorage.getItem('user'))

  //cost calculation
  const [costCalculationAtom, setCostCalculationAtom] =
    useRecoilState(cost_calculation)
  const [costCalculationNRMAtom, setCostCalculationNRMAtom] =
    useRecoilState(cost_calculation_nrm)
  const costCalculatedValues = useRecoilValue(costCalculation)
  const costCalculatedNRMValues = useRecoilValue(costCalculationNRM)
  const access_token = localStorage.getItem('accesstoken')

  const handlePrevious = () => {
    if (activeTab === "ConsumptionDetailsTab") {
      if (currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
        CostSavingRef.current.handlePrevious();
      }
    } else if (activeTab === "SaveDetailsTab") {
      if (currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
        CostSavingRef.current.handlePrevious();
      } else {
        setActiveTab("ConsumptionDetailsTab");
        setCurrentStep(6); // Move to the last step of the first tab
        CostSavingRef.current.handlePrevious();
      }
    }
  };

  const custDettObj = {
    custDet: {
      customerDetails: custDet,
    },
  }

  // consumptions Details
  const furnaceDetailsObj = {
    furnaceDetails: {
      basic: furnaceDetails,
      nrm: furnaceDetailsNRM,
    },
  }
  const newLiningObj = {
    newLiningDetails: {
      basic: newLining,
      nrm: newLiningNrm,
    },
  }
  const sideLiningObj = {
    sideLiningDetails: {
      basic: sideLining,
      nrm: sideLiningNrm,
    },
  }
  const patchingObj = {
    patchingDetails: {
      basic: patchingDetails,
      nrm: patchingDetailsNrm,
    },
  }
  const repairDetailsObj = {
    repairingDetails: {
      basic: repairingDetails,
      nrm: repairingDetailsNrm,
    },
  }

  const laborCostObj = {
    ...laborCost,
    labor_cost_campaign: laborCostCampaign,
    labor_cost_per_steel_produced: laborCostPerTon,
    savings_labor_cost: 'NA',
  }
  console.log(laborCostObj, 'laborCostObj')

  const laborCostObjNRM = {
    ...laborCostNRM,
    labor_cost_campaign: laborCostCampaignNRM,
    labor_cost_per_steel_produced: laborCostPerTonNRM,
    savings_labor_cost: SavingInLaborCostNRM,
  }

  const laborCostDataObject = [
    {
      laborCostDataObject: {
        laborCost: {
          basic: laborCostObj,
          nrm: laborCostObjNRM,
        },
      },
    },
  ]
  const formerCostObj = {
    ...formerCost,
    former_cost_campaign: FormerCostCampaign,
    former_cost_per_steel_produced: formerCostPerTon,
    savings_former_cost: 'NA',
  }

  const formerCostObjNRM = {
    ...formerCostNRM,
    former_cost_campaign: FormerCostCampaignNRM,
    former_cost_per_steel_produced: formerCostPerTonNRM,
    savings_former_cost: savingInFormerCost,
  }

  const formerCostDataObject = [
    {
      formerCostDataObject: {
        formerCost: {
          basic: formerCostObj,
          nrm: formerCostObjNRM,
        },
      },
    },
  ]

  const IncreasedProdObj = {
    ...increasedProductivity,
    maxNoOfHeats: maxNoOfHeats,
    noOfSintering: noOfSintering,
    totDownTime: totDownTime,
    totDownTimeDueToFce: totDownTimeDueToFce,
    totTimeAvailableProd: totTimeAvailableProd,
    actualNoOfHeats: actualNoOfHeats,
    actualProdInMonth: actualProdInMonth,
    production_increase_due_to_nrm: 'NA',
    percentage_production_increase: 'NA',
    profit_margin: 'NA',
    cost_benefit: 'NA',
    saving_due_to_increased_productivity: 'NA',
  }

  const IncreasedProdObjNRM = {
    ...increasedProductivityNRM,
    maxNoOfHeats: maxNoOfHeatsNRM,
    noOfSintering: noOfSinteringNRM,
    totDownTime: totDownTimeNRM,
    totDownTimeDueToFce: totDownTimeDueToFceNRM,
    totTimeAvailableProd: totTimeAvailableProdNRM,
    actualNoOfHeats: actualNoOfHeatsNRM,
    actualProdInMonth: actualProdInMonthNRM,
    production_increase_due_to_nrm: prodIncrease,
    percentage_production_increase: perOfProdDueToNRM,
    cost_benefit: costBenefits,
    saving_due_to_increased_productivity: savingDueToIncProd,
  }

  const increasedProductivityDataObject = [
    {
      increasedProductivityObject: {
        IncreasedProductivity: {
          basic: IncreasedProdObj,
          nrm: IncreasedProdObjNRM,
        },
      },
    },
  ]


  const energyObj = { ...energy }

  const energyObjNRM = {
    ...energyNRM,
    savings_power_consumption: costSavingsDutToPower,
  }

  const energyDataObject = [
    {
      energyDataObject: {
        energy: {
          basic: energyObj,
          nrm: energyObjNRM,
        },
      },
    },
  ]

  // yield improvement
  const yieldImprovementObj = {
    ...yieldImprovement,
  }

  const yieldImprovementObjNRM = {
    ...yieldImprovementNRM,
    savings_due_to_yield_benefit: costSavingsDueToPower,
  }

  const yieldImprovementDataObject = [
    {
      yieldImprovementObject: {
        yieldImprovement: {
          basic: yieldImprovementObj,
          nrm: yieldImprovementObjNRM,
        },
      },
    },
  ]

  // alloy

  const alloyObj = { ...alloy }

  const alloyObjNRM = {
    ...alloyNRM,
    savings_due_to_reduction: savingsDueToReduction,
  }

  const alloyDataObject = [
    {
      alloyObject: {
        alloy: {
          basic: alloyObj,
          nrm: alloyObjNRM,
        },
      },
    },
  ]

  // tangilable benefits
  const tangibleBenefitsObj = {
    ...tangibleBenefits,
  }

  const tangibleBenefitsObjNRM = {
    ...tangibleBenefitsNRM,
    other_tangible_benefits: otherTangibleBenefits,
  }
  const tangibleBenefitsDataObject = [
    {
      tangibleBenefitsObject: {
        tangibleBenefits: {
          basic: tangibleBenefitsObj,
          nrm: tangibleBenefitsObjNRM,
        },
      },
    },
  ]

  const metaObj = {
    savings_metallurgical: 'NA',
  }

  const metaObjNRM = {
    savings_metallurgical: metallurgicalBenefitsNRM.savings_metallurgical,
  }

  // sintering heats

  const chargedSinteringPerMonth = useRecoilValue(
    chargedSinteringPerMonthSelector
  )
  const costSinteringHeatPerMonth = useRecoilValue(
    costSinteringHeatPerMonthSelector
  )
  const costOfReplacingWithDRI = useRecoilValue(costOfReplacingWithDRISelector)
  const costOfchargedSinteringHeatPerMT = useRecoilValue(
    costOfchargedSinteringHeatPerMTSelector
  )
  // nrm
  const chargedSinteringPerMonthNRM = useRecoilValue(
    chargedSinteringPerMonthNRMSelector
  )
  const costSinteringHeatPerMonthNRM = useRecoilValue(
    costSinteringHeatPerMonthNRMSelector
  )
  const costOfReplacingWithDRINRM = useRecoilValue(
    costOfReplacingWithDRINRMSelector
  )
  const costOfchargedSinteringHeatPerMTNRM = useRecoilValue(
    costOfchargedSinteringHeatPerMTNRMSelector
  )

  const sinteringHeatObj = {
    ...sinteringHeats,
    excess_scrap_charged__sintering_perMonth: chargedSinteringPerMonth,
    cost_charged_due_sinteringHeat_perMonth: costSinteringHeatPerMonth,
    cost_of_replacing_with_dri: costOfReplacingWithDRI,
    cost_charged_sinteringHeat_per_MT: costOfchargedSinteringHeatPerMT,
  }

  const sinteringHeaNRMtObj = {
    ...sinteringHeatsNRM,
    excess_scrap_charged__sintering_perMonth: chargedSinteringPerMonthNRM,
    cost_charged_due_sinteringHeat_perMonth: costSinteringHeatPerMonthNRM,
    cost_of_replacing_with_dri: costOfReplacingWithDRINRM,
    cost_charged_sinteringHeat_per_MT: costOfchargedSinteringHeatPerMTNRM,
  }

  const metallurgicalBenefitsDataObject = [
    {
      metallurgicalBenefitsObject: {
        metallurgicalBenefits: {
          basic: metaObj,
          nrm: metaObjNRM,
        },
      },
    },
  ]

  const sinteringHeatDataObject = [
    {
      sinteringHeatBenefitsObject: {
        sinteringHeat: {
          basic: sinteringHeatObj,
          nrm: sinteringHeaNRMtObj,
        },
      },
    },
  ]
  // cost calculation

  const savedCalculationObj = JSON.parse(
    localStorage.getItem('calculationObject')
  )
  const savedCalculationNRMObj = JSON.parse(
    localStorage.getItem('calculationObjectNRM')
  )

  console.log(savedCalculationNRMObj, 'savedCalculationNRMObj')
  console.log(savedCalculationObj, 'savedCalculationObj')

  const costCalculationObj = {
    costCalculation: {
      basic: {
        ...costCalculationAtom,
        avg_life: costCalculatedValues.averageLife,
        tot_prod: costCalculatedValues.totProduction,
        no_of_heats: costCalculatedValues.totNoOfHeats,
        no_of_cycles: costCalculatedValues.totNoOfCycle,
        tot_refractory: costCalculatedValues.tot_refractory,
        avg_in_heat_size: costCalculatedValues.averageHeatSize,
        cost_in_campaign_2: costCalculatedValues.toppingMaterialCost_2,
        cost_in_campaign_3: costCalculatedValues.toppingMaterialCost_3,
        cost_of_refractory: costCalculatedValues.costOfrefractory,
        tot_cost_in_campaign: costCalculatedValues.totRefractoryConsumption,
        spec_refractory_consumpt:
          costCalculatedValues.SpecificRefractoryConsumpt,
        ramming_cost_in_campaign_1: costCalculatedValues.rammingMaterialCost_1,
        tot_refractory_consumpt: costCalculatedValues.totRefractoryConsumption,
      },
      nrm: {
        ...costCalculationNRMAtom,
        avg_in_heat_size: costCalculatedNRMValues.averageHeatSizeNRM,
        avg_life: costCalculatedNRMValues.averageLifeNRM,
        no_of_cycles: costCalculatedNRMValues.totNoOfCycleNRM,
        no_of_heats: costCalculatedNRMValues.totNoOfHeatsNRM,
        tot_prod: costCalculatedNRMValues.totProductionNRM,
        ramming_cost_in_campaign_1:
          costCalculatedNRMValues.rammingMaterialCostNRM_1,
        cost_in_campaign_2: costCalculatedNRMValues.toppingMaterialCostNRM_2,
        cost_in_campaign_3: costCalculatedNRMValues.toppingMaterialCostNRM_3,
        tot_cost_in_campaign:
          costCalculatedNRMValues.totCostCampaignRefractoryNRM,
        tot_refractory: costCalculatedNRMValues.totRefractoryConsumptionNRM,
        spec_refractory_consumpt:
          costCalculatedNRMValues.SpecificRefractoryConsumptNRM,
        cost_of_refractory: costCalculatedNRMValues.costOfrefractoryNRM,
        inc_in_cost: costCalculatedNRMValues.increaseInCostDueToNRM,
        relative_gain: costCalculatedNRMValues.relativeGainNRM,
      },
    },
  }

  console.log(costCalculationObj, 'costCalculationObj')

  const consumptionObjects = [
    furnaceDetailsObj,
    newLiningObj,
    sideLiningObj,
    patchingObj,
    repairDetailsObj,
    { material_type: type },
  ];

  console.log(consumptionObjects, 'consumptionObjects123')

  const costCalculationObjects = costCalculationObj
  console.log(costCalculationObjects, 'costCalculationObjects')

  const savingsObject = [
    laborCostDataObject,
    formerCostDataObject,
    increasedProductivityDataObject,
    energyDataObject,
    yieldImprovementDataObject,
    alloyDataObject,
    tangibleBenefitsDataObject,
    metallurgicalBenefitsDataObject,
    sinteringHeatDataObject,
  ]

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(API.GET_ALL_COST_SHEETS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data
      console.log(data, "check3874")
      let currentID = localStorage.getItem('costSheetID')
      setCostSheet(data.find((x) => x.id === currentID))
      setTriggerEvent(true)
      console.log(CostSheet, "chejkjshd")
    } catch (error) { }
  }


  useEffect(() => {
    if (
      trialName === 'costSheetEdit' ||
      (trialName === 'viewCostSheet' && CostSheet)
    ) {
      console.log(CostSheet, "CostSheet435")
      const uploadID = CostSheet.datafile_id
      localStorage.setItem('uploadID', uploadID)
      if (
        CostSheet.consumption_details &&
        CostSheet.consumption_details.length > 0 &&
        CostSheet.saving_details &&
        CostSheet.saving_details.length > 0
      ) {
        setFurnaceDetails(CostSheet?.consumption_details?.[0]?.furnaceDetails?.basic || {});
        setFurnaceDetailsNRM(CostSheet?.consumption_details?.[0]?.furnaceDetails?.nrm || {});
        setNewLining(CostSheet?.consumption_details?.[1]?.newLiningDetails?.basic || {});
        setNewLiningNrm(CostSheet?.consumption_details?.[1]?.newLiningDetails?.nrm || {});
        setSideLining(CostSheet?.consumption_details?.[2]?.sideLiningDetails?.basic || {});
        setSideLiningNrm(CostSheet?.consumption_details?.[2]?.sideLiningDetails?.nrm || {});
        setPatchingDetails(CostSheet?.consumption_details?.[3]?.patchingDetails?.basic || {});
        setPatchingDetailsNrm(CostSheet?.consumption_details?.[3]?.patchingDetails?.nrm || {});
        setRepairingDetails(CostSheet?.consumption_details?.[4]?.repairingDetails?.basic || {});
        setRepairingDetailsNrm(CostSheet?.consumption_details?.[4]?.repairingDetails?.nrm || {});
        setType(CostSheet?.consumption_details?.[5]?.material_type || {})

        setCustDet(CostSheet?.general_details?.custDet.customerDetails)

        // // savings details
        setLaborCost(CostSheet?.saving_details?.[0][0]?.laborCostDataObject?.laborCost?.basic || {});
        setLaborCostNRM(CostSheet?.saving_details?.[0][0]?.laborCostDataObject?.laborCost?.nrm || {});
        setFormerCost(CostSheet?.saving_details?.[1][0]?.formerCostDataObject?.formerCost?.basic || {});
        setFormerCostNRM(CostSheet?.saving_details?.[1][0]?.formerCostDataObject?.formerCost?.nrm || {});
        setIncreasedProductivity(CostSheet?.saving_details?.[2][0]?.increasedProductivityObject?.IncreasedProductivity?.basic || {});
        setIncreasedProductivityNRM(CostSheet?.saving_details?.[2][0]?.increasedProductivityObject?.IncreasedProductivity?.nrm || {});
        setEnergy(CostSheet?.saving_details?.[3][0]?.energyDataObject?.energy?.basic || {});
        setEnergyNRM(CostSheet?.saving_details?.[3][0]?.energyDataObject?.energy?.nrm || {});
        setYieldImprovement(CostSheet?.saving_details?.[4][0]?.yieldImprovementObject?.yieldImprovement?.basic || {});
        setYieldImprovementNRM(CostSheet?.saving_details?.[4][0]?.yieldImprovementObject?.yieldImprovement?.nrm || {});
        setAlloy(CostSheet?.saving_details?.[5][0]?.alloyObject?.alloy?.basic || {});
        setAlloyNRM(CostSheet?.saving_details?.[5][0]?.alloyObject?.alloy?.nrm || {});
        setTangibleBenefits(CostSheet?.saving_details?.[6][0]?.tangibleBenefitsObject?.tangibleBenefits?.basic || {});
        setTangibleBenefitsNRM(CostSheet?.saving_details?.[6][0]?.tangibleBenefitsObject?.tangibleBenefits?.nrm || {});
        setMetallurgicalBenefits(CostSheet?.saving_details?.[7][0]?.metallurgicalBenefitsObject?.metallurgicalBenefits?.basic || {});
        setMetallurgicalBenefitsNRM(CostSheet?.saving_details?.[7][0]?.metallurgicalBenefitsObject?.metallurgicalBenefits?.nrm || {});
        setSinteringHeats(CostSheet?.saving_details?.[8][0]?.sinteringHeatBenefitsObject?.sinteringHeat?.basic || {});
        setSinteringHeatsNRM(CostSheet?.saving_details?.[8][0]?.sinteringHeatBenefitsObject?.sinteringHeat?.nrm || {});

        // // calculations
        setCostCalculationAtom(CostSheet?.cost_calculation?.costCalculation?.basic || {});
        setCostCalculationNRMAtom(CostSheet?.cost_calculation?.costCalculation?.nrm || {});

      }
    }
  }, [triggerEvent])

  const handleDraft = async () => {
    if (trialName === 'costSheetEdit') {
      try {
        const uploadId = localStorage.getItem('datafile_id')
        console.log('uploadIduploadId', uploadId)
        const data = {
          datafile_id: uploadId,
          submission_status: 'draft',
          updated_data: {
            general_details: custDettObj,
            consumption_details: consumptionObjects,
            saving_details: savingsObject,
            cost_calculation: costCalculationObjects,
          },
        }

        console.log(data, 'update data')
        const response = await axios.put(API.UPDATE_COST_CALCULATION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('Responsecost:', response.data)
        if (response.data.submission_status === "submitted") {
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
        } else if (response.data.submission_status === "approved") {
          setStatusApproved(true);
          setStatusSubmitted(false);
          setStatusDraft(false);
        } else if (response.data.submission_status === "draft") {
          setStatusDraft(true)
          setStatusApproved(false);
          setStatusSubmitted(false);
        }
      } catch (error) {
        // Handle errors
        console.error('An error occurred while updating data:', error)
      }
    } else {
      try {
        const uploadId = localStorage.getItem('datafile_id')
        console.log('uploadIduploadId', uploadId)
        const data = {
          datafile_id: uploadId,
          submission_status: 'draft',
          data: [
            {
              datafile_id: uploadId,
              general_details: custDettObj,
              consumption_details: consumptionObjects,
              saving_details: savingsObject,
              cost_calculation: costCalculationObjects,
            },
          ],
        }

        console.log(data, 'data123')

        const response = await axios.post(API.INSERT_COST_CALCULATION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })

        console.log('Data inserted successfully cost sheet:', response)

        if (response.data.submission_status === "submitted") {
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
        } else if (response.data.submission_status === "approved") {
          setStatusApproved(true);
          setStatusSubmitted(false);
          setStatusDraft(false);
        } else if (response.data.submission_status === "draft") {
          setStatusDraft(true)
          setStatusApproved(false);
          setStatusSubmitted(false);
        }
      } catch (error) {
        // Handle errors
        console.error('An error occurred while inserting data:', error)
      }
    }
  }
  const submitFormData = async () => {
    try {
      let submissionStatus = ''
      if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
        submissionStatus = 'approved'
      } else {
        submissionStatus = 'submitted'
      }
      const uploadId = localStorage.getItem('datafile_id')
      console.log('uploadIduploadId', uploadId)
      const data = {
        datafile_id: uploadId,
        submission_status: submissionStatus,
        data: [
          {
            datafile_id: uploadId,
            general_details: custDettObj,
            consumption_details: consumptionObjects,
            saving_details: savingsObject,
            cost_calculation: costCalculationObjects,
          },
        ],
      }

      console.log(data, 'data123')

      const response = await axios.post(API.INSERT_COST_CALCULATION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })

      console.log('Data inserted successfully:', response.data)
      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Form submitted successfully!',
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            closeModal()
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }

      if (response.data.submission_status === "submitted") {
        setStatusSubmitted(true);
        setStatusDraft(false);
        setStatusApproved(false);
      } else if (response.data.submission_status === "approved") {
        setStatusApproved(true);
        setStatusSubmitted(false);
        setStatusDraft(false);
      } else if (response.data.submission_status === "draft") {
        setStatusDraft(true)
        setStatusApproved(false);
        setStatusSubmitted(false);
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error)
    }
  }
  const updateFormData = async () => {
    try {
      const uploadId = localStorage.getItem('datafile_id')
      console.log('uploadIduploadId', uploadId)
      const data = {
        datafile_id: uploadId,
        submission_status: 'submitted',
        updated_data: {
          general_details: custDettObj,
          consumption_details: consumptionObjects,
          saving_details: savingsObject,
          cost_calculation: costCalculationObjects,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_COST_CALCULATION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        if (response.data.submission_status === "submitted") {
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
        } else if (response.data.submission_status === "approved") {
          setStatusApproved(true);
          setStatusSubmitted(false);
          setStatusDraft(false);
        } else if (response.data.submission_status === "draft") {
          setStatusDraft(true)
          setStatusApproved(false);
          setStatusSubmitted(false);
        }
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }
  const handleApprove = async () => {
    try {
      const uploadId = localStorage.getItem('datafile_id')
      console.log('uploadIduploadId', uploadId)
      const data = {
        datafile_id: uploadId,
        submission_status: 'approved',
        updated_data: {
          general_details: custDettObj,
          consumption_details: consumptionObjects,
          saving_details: savingsObject,
          cost_calculation: costCalculationObjects,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_COST_CALCULATION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        // Navigate after successful update
        // navigate('/submittedstatus')
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const handleNext = async () => {
    if (activeTab === "ConsumptionDetailsTab") {
      if (currentStep < 6) {
        setCurrentStep((prevStep) => prevStep + 1);
        CostSavingRef.current.handleNext();
      } else if (currentStep === 6) {
        setActiveTab("SaveDetailsTab");
        setCurrentStep(1); // Move to the first step of the second tab
        CostSavingRef.current.handleNext();
      }
    } else if (activeTab === "SaveDetailsTab") {
      if (currentStep < 9) {
        setCurrentStep((prevStep) => prevStep + 1);
        CostSavingRef.current.handleNext();
      } else if (currentStep === 9) {
        setActiveTab("CostCalucalationTab");
        setCurrentStep(1); // Move to the third tab
        CostSavingRef.current.handleNext();
      }
    } else if (activeTab === "CostCalucalationTab") {
      if (trialName === "costSheetEdit") {
        await updateFormData();
      } else {
        await submitFormData();
      }
    }
  };

  const renderDisable = () => {
    if (currentStep === 1 && (activeTab === 'ConsumptionDetailsTab' || activeStep === 'SaveDetailsTab' || activeTab === 'CostCalucalationTab')) {
      return true;
    }
  };
  const renderButtons = () => (
    <div className="flex space-x-4 justify-center bg-white w-full py-4 pr-4">
      <button
        onClick={handlePrevious}
        className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
        disabled={renderDisable()}
      >
        Previous
      </button>
      <button
        onClick={handleDraft}
        className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg"
      >
        Save Draft
      </button>
      <button
        onClick={handleNext}
        className="text-white bg-custom-red w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
        disabled={currentStep === 6 && !true}
      >
        {activeTab === "CostCalucalationTab" ? "Submit" : "Next"}
      </button>
    </div>
  );



  return (
    <>
      {statusApproved && <ApproveStatus closeModal={closeModal} />}
      {statusDraft && <DraftStatus closeModal={closeModal} />}
      {statusSubmitted && <SubmittedStatus closeModal={closeModal} />}
      {!statusApproved && !statusDraft && !statusSubmitted && (

        <div className="w-full">
          <ul className="flex -mb-px text-sm font-medium text-center overflow-auto">
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("ConsumptionDetailsTab")}
                className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "ConsumptionDetailsTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
                  }`}
                type="button"
                role="tab"
                aria-controls="ConsumptionDetailsTab"
                aria-selected={activeTab === "ConsumptionDetailsTab"}
              >
                Consumption Details
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("SaveDetailsTab")}
                className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "SaveDetailsTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
                  }`}
                type="button"
                role="tab"
                aria-controls="SaveDetailsTab"
                aria-selected={activeTab === "SaveDetailsTab"}
              >
                Saving Details
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("CostCalucalationTab")}
                className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "CostCalucalationTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
                  }`}
                type="button"
                role="tab"
                aria-controls="CostCalucalationTab"
                aria-selected={activeTab === "CostCalucalationTab"}
              >
                Cost Calculation
              </button>
            </li>
          </ul>
          <div id="default-tab-content">
            <div
              className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "ConsumptionDetailsTab" ? "block" : "hidden"
                }`}
              id="ConsumptionDetailsTab"
              role="tabpanel"
              aria-labelledby="ConsumptionDetailsTab-tab"
            >
              <ConsumptionLayout ref={CostSavingRef} />
              {renderButtons()}
            </div>

            <div
              className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "SaveDetailsTab" ? "block" : "hidden"
                }`}
              id="SaveDetailsTab"
              role="tabpanel"
              aria-labelledby="SaveDetailsTab-tab"
            >
              <SaveDetailsLayout ref={CostSavingRef}/>
              {renderButtons()}
            </div>

            <div
              className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "CostCalucalationTab" ? "block" : "hidden"
                }`}
              id="CostCalucalationTab"
              role="tabpanel"
              aria-labelledby="CostCalucalationTab-tab"
            >
              <CostCalculation />
              {renderButtons()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CostSheetLayout;
