import React from "react";
import { useRecoilState } from "recoil";
import { trial_name, raw_materials_energy_details } from "../../recoil/atom";

const FurnaceDimension = () => {
  const [trialName] = useRecoilState(trial_name)
  const [energyDetails, setEnergyDetails] = useRecoilState(
    raw_materials_energy_details
  )
  console.log(energyDetails, 'energyDetailsenergyDetails')

  const handleChange = (e) => {
    const { name, value } = e.target
    setEnergyDetails((prevEnergyDetails) => ({
      ...prevEnergyDetails,
      [name]: value,
    }))
  }

  const isReadOnly = trialName === 'viewDataCollection'
  
  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Raw Material & Energy Details
      </h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="type_of_scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Type of Scrap
          </label>
          <input
            type="text"
            id="type_of_scrap"
            name="type_of_scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Type"
            value={energyDetails.type_of_scrap}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Date */}
        <div className="w-full mb-4">
          <label
            htmlFor="charge_mix_ratio"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Charge Mix Ratio (Scrap : PI : DRI)
          </label>
          <input
            type="text"
            id="charge_mix_ratio"
            name="charge_mix_ratio"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Ratio"
            value={energyDetails.charge_mix_ratio}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="yield_of_charge_mix"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield of Charge Mix - %
          </label>
          <input
            type="text"
            id="yield_of_charge_mix"
            name="yield_of_charge_mix"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Yield"
            value={energyDetails.yield_of_charge_mix}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="profit"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Profit/kg - INR/Kg
          </label>
          <input
            type="text"
            id="profit"
            name="profit"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter profit"
            value={energyDetails.profit}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="unit_price_of_power"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Unit Price of Power - INR/kWh
          </label>
          <input
            type="text"
            id="unit_price_of_power"
            name="unit_price_of_power"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Price"
            value={energyDetails.unit_price_of_power}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

      </div>
    </>
  );
};

export default FurnaceDimension;
