import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { savings_alloy, savings_alloy_nrm, trial_name, materialType } from "../../../recoil/atom";
import { savingsDueToReductionSelector } from '../../../recoil/selector'
import Helper from "../../../service/Helper";
const Alloy = () => {
  const [alloy, setAlloy] = useRecoilState(savings_alloy)
  const [alloyNRM, setAlloyNRM] = useRecoilState(savings_alloy_nrm)
  const [trialName] = useRecoilState(trial_name)
  const [type, setType] = useRecoilState(materialType)

  const savingsDueToReduction = useRecoilValue(savingsDueToReductionSelector)
  console.log('Savings Due to Reduction:', savingsDueToReduction)

  const handleAlloy = (name, value) => {
    setAlloy((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAlloyNRM = (name, value) => {
    setAlloyNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S5 : Savings in Alloy
      </h2>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            SiMn Savings (dia /MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Savings"
            value="NA"
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            FeCr Savings (dia /MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Savings"
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to other alloys , if applicable  - (Al) (dia /MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          onChange={() => { }}
          value="NA"
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to reduction in alloy addition (dia /MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          onChange={() => { }}
          value="NA"
        />
      </div>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>

      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="simn_savings"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            SiMn Savings (dia /MT)
          </label>
          <input
            type="text"
            id="simn_savings"
            name="simn_savings"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Savings"
            value={alloyNRM.simn_savings}
            onChange={(event) =>
              handleAlloyNRM('simn_savings', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="fecr_savings"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            FeCr Savings (dia /MT)
          </label>
          <input
            type="text"
            id="fecr_savings"
            name="fecr_savings"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Savings"
            value={alloyNRM.fecr_savings}
            onChange={(event) =>
              handleAlloyNRM('fecr_savings', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="savings_due_to_other_alloy"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to other alloys , if applicable  - (Al) (dia /MT)
        </label>
        <input
          type="text"
          id="savings_due_to_other_alloy"
          name="savings_due_to_other_alloy"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={alloyNRM.savings_due_to_other_alloy}
          onChange={(event) =>
            handleAlloyNRM('savings_due_to_other_alloy', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="savings_due_to_reduction"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to reduction in alloy addition (dia /MT)
        </label>
        <input
          type="text"
          id="savings_due_to_reduction"
          name="savings_due_to_reduction"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={savingsDueToReduction}
          onChange={(event) =>
            handleAlloyNRM('savings_due_to_reduction', event.target.value)
          }
        />
      </div>
    </>
  );
};

export default Alloy;
