import React from 'react';
import { useRecoilState } from 'recoil';
import { lining_side_left, lining_side_right, lining_side_spout, lining_side_back, trial_name } from '../../recoil/atom';
import { v4 as uuidv4 } from 'uuid'
const LiningSideWeb = () => {
  const [left, setLeft] = useRecoilState(lining_side_left)
  const [right, setRight] = useRecoilState(lining_side_right)
  const [spout, setSpout] = useRecoilState(lining_side_spout)
  const [back, setBack] = useRecoilState(lining_side_back)
  console.log(back, "back67")
  const uuid = uuidv4()

  console.log(left, "leftleft")

  const handleChange = (name, value, setState) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewErosionProfile'
  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">Lining Side</h2>
      <h2 className="text-l font-medium text-gray-400 mb-4">Lining Side-Left</h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label htmlFor="measurement_of_4_phase" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Total left over thickness (LOT) - (Inch)
          </label>
          <input
            type="text"
            id="measurement_of_4_phase"
            name="measurement_of_4_phase"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Measurement"
            value={left.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="black_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Black hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="black_sintered_layer_thickness"
            name="black_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={left.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="white_hard_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            White hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="white_hard_sintered_layer_thickness"
            name="white_hard_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={left.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="semi_centered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Semi hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="semi_centered_layer_thickness"
            name="semi_centered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={left.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="powder_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Powder layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="powder_layer_thickness"
            name="powder_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={left.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="chipping_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Chipping Height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="chipping_height"
            name="chipping_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={left.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="breaking_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Breaking height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="breaking_height"
            name="breaking_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height."
            value={left.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        {/* <div className="w-full mb-4">
          <label htmlFor="customer_ref_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Remarks
          </label>
          <input
            type="text"
            id="remarks"
            name="remarks"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Remarks"
            value={left.remarks}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div> */}
      </div>

      {/* // Right */}

      <h2 className="text-l font-medium text-gray-400 mb-4">Lining Side-Right</h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label htmlFor="measurement_of_4_phase" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Total left over thickness (LOT) - (Inch)
          </label>
          <input
            type="text"
            id="measurement_of_4_phase"
            name="measurement_of_4_phase"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Measurement"
            value={right.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="black_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Black hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="black_sintered_layer_thickness"
            name="black_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={right.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="white_hard_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            White hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="white_hard_sintered_layer_thickness"
            name="white_hard_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={right.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="semi_centered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Semi hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="semi_centered_layer_thickness"
            name="semi_centered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={right.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="powder_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Powder layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="powder_layer_thickness"
            name="powder_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={right.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="chipping_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Chipping Height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="chipping_height"
            name="chipping_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={right.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="breaking_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Breaking height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="breaking_height"
            name="breaking_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={right.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        {/* <div className="w-full mb-4">
          <label htmlFor="customer_ref_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Remarks
          </label>
          <input
            type="text"
            id="remarks"
            name="remarks"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Remarks"
            value={left.remarks}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div> */}
      </div>

      {/* spout */}
      <h2 className="text-l font-medium text-gray-400 mb-4">Lining Side-Spout</h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label htmlFor="measurement_of_4_phase" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Total left over thickness (LOT) - (Inch)
          </label>
          <input
            type="text"
            id="measurement_of_4_phase"
            name="measurement_of_4_phase"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Measurement"
            value={spout.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="black_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Black hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="black_sintered_layer_thickness"
            name="black_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={spout.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="white_hard_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            White hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="white_hard_sintered_layer_thickness"
            name="white_hard_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={spout.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="semi_centered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Semi hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="semi_centered_layer_thickness"
            name="semi_centered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={spout.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="powder_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Powder layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="powder_layer_thickness"
            name="powder_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={spout.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="chipping_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Chipping Height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="chipping_height"
            name="chipping_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={spout.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="breaking_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Breaking height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="breaking_height"
            name="breaking_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={spout.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>


      {/* Back  */}

      <h2 className="text-l font-medium text-gray-400 mb-4">Lining Side-Back</h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label htmlFor="measurement_of_4_phase" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Total left over thickness (LOT) - (Inch)
          </label>
          <input
            type="text"
            id="measurement_of_4_phase"
            name="measurement_of_4_phase"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Measurement"
            value={back.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="black_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Black hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="black_sintered_layer_thickness"
            name="black_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={back.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="white_hard_sintered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            White hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="white_hard_sintered_layer_thickness"
            name="white_hard_sintered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={back.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="semi_centered_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Semi hard layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="semi_centered_layer_thickness"
            name="semi_centered_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={back.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="powder_layer_thickness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Powder layer thickness - (Inch)
          </label>
          <input
            type="text"
            id="powder_layer_thickness"
            name="powder_layer_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Thickness"
            value={back.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="chipping_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Chipping Height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="chipping_height"
            name="chipping_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={back.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="breaking_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Breaking height (Top to Bottom) - (Inch)
          </label>
          <input
            type="text"
            id="breaking_height"
            name="breaking_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={back.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="customer_ref_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Remarks
          </label>
          <input
            type="text"
            id="remarks"
            name="remarks"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Remarks"
            value={back.remarks}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
          />
        </div>
      </div>



    </>
  );
};

export default LiningSideWeb;
