import React, { useState, useEffect } from "react";
import CalendarView from "./CalendarView";
import StackedBarChart from "./BarChart";
import moment from "moment"; // Required for momentLocalizer
import TableIcon from "../../assets/images/icons/TableIcon.svg";
import Board from "../../assets/images/icons/BoardIcon.svg";
import {momentLocalizer } from "react-big-calendar";
import axios from "axios";
import API from "../../service/API";
import DateRangePicker from "../Common/DateRangePicker";

const localizer = momentLocalizer(moment);

export default function DashboardLayout() {
  const [isCalendarView, setIsCalendarView] = useState(true);
  const [events] = useState([]);
  const [region, setRegion] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  console.log(selectedRegion, "selectedRegion")
  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [erosionProfileCounts, setErosionProfileCounts] = useState({
    approved: 0,
    rejected: 0,
  });
  const [dataCollectionCounts, setDataCollectionCounts] = useState({
    approved: 0,
    rejected: 0,
  });
  const [costSheetCounts, setCostSheetCounts] = useState({
    approved: 0,
    rejected: 0,
  });
  const [dailyTrialCounts, setDailyTrialCounts] = useState({
    approved: 0,
    rejected: 0,
  });
  const [nrmTrialCounts, setNRMPerformanceCounts] = useState({
    approved: 0,
    rejected: 0,
  });

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  console.log(selectedRegion, "heheh");
  const handleToggle = () => {
    setIsCalendarView((prev) => !prev);
  };

  const access_token = localStorage.getItem("accesstoken");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const storedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
  console.log(currentUser.id, "userr");

  const fetchRegion = async () => {
    try {
      const response = await axios.get(API.REGION, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const regions = response.data;
      setRegion(regions);

      // Set the default region from the current user, if available
      if (currentUser?.Region?.id) {
        setSelectedRegion(currentUser.Region.id);
      } else if (regions.length > 0) {
        // Fallback to the first region if currentUser's region is not available
        setSelectedRegion(regions[0].id);
      }
    } catch (error) {
      console.error("Error fetching region:", error);
    }
  };

  useEffect(() => {
    // Ensure fetchRegion is called only once when the component mounts
    fetchRegion();
  }, []); // Empty dependency array ensures this runs once

  useEffect(() => {
    // Fetch data whenever selectedRegion or selectedDates change
    if (selectedRegion) {
      fetchData();
    }
  }, [selectedRegion]); // Ensure dependencies are correct

  useEffect(() => {
    fetchData();
  }, [selectedRegion, selectedDates]);

  const fetchData = async () => {
    try {
      const params = {
        customerId: storedCustomer?.id,
        regionId: selectedRegion,
        startDate: selectedDates?.startDate,
        endDate: selectedDates?.endDate,
      };

      const [
        dailyTrialResponse,
        nrmPerformanceResponse,
        erosionProfileResponse,
        dataCollectionResponse,
        costSheetResponse,
      ] = await Promise.all([
        axios.get(API.GET_DTM_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_NRM_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_EROSION_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_DATACOLLECTION_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_COSTSHEET_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
      ]);

      const dailyTrialData = dailyTrialResponse?.data || [];
      const nrmPerformanceData = nrmPerformanceResponse?.data || [];
      const erosionProfileData = erosionProfileResponse?.data || [];
      const dataCollectionData = dataCollectionResponse?.data || [];
      const costSheetData = costSheetResponse?.data || [];

      console.log("Daily Trial Response:", dailyTrialData);
      console.log("NRM Performance Response:", nrmPerformanceData);
      console.log("Erosion Profile Response:", erosionProfileData);
      console.log("Data Collection Response:", dataCollectionData);
      console.log("Cost Sheet Response:", costSheetData);

      let approvedDailyTrial = 0;
      let rejectedDailyTrial = 0;
      let approvedNRMPerformance = 0;
      let rejectedNRMPerformance = 0;

      dailyTrialData.forEach((item) => {
        const generalDetail = item?.general_details?.find(
          (detail) => detail?.submission_status
        );
        if (generalDetail?.submission_status === "approved") {
          approvedDailyTrial++;
        } else if (generalDetail?.submission_status === "rejected") {
          rejectedDailyTrial++;
        }

        const liningRef = item?.lining_reference?.find(
          (lining) => lining?.submission_status
        );
        if (liningRef?.submission_status === "approved") {
          approvedNRMPerformance++;
        } else if (liningRef?.submission_status === "rejected") {
          rejectedNRMPerformance++;
        }
      });

      nrmPerformanceData.forEach((item) => {
        const generalDetail = item?.general_details?.find(
          (detail) => detail?.submission_status
        );
        if (generalDetail?.submission_status === "approved") {
          approvedNRMPerformance++;
        } else if (generalDetail?.submission_status === "rejected") {
          rejectedNRMPerformance++;
        }

        const liningRef = item?.lining_reference?.find(
          (lining) => lining?.submission_status
        );
        if (liningRef?.submission_status === "approved") {
          approvedNRMPerformance++;
        } else if (liningRef?.submission_status === "rejected") {
          rejectedNRMPerformance++;
        }
      });

      setDailyTrialCounts({
        approved: approvedDailyTrial,
        rejected: rejectedDailyTrial,
      });
      setNRMPerformanceCounts({
        approved: approvedNRMPerformance,
        rejected: rejectedNRMPerformance,
      });

      console.log(
        "Daily Trial Counts:",
        approvedDailyTrial,
        rejectedDailyTrial
      );
      console.log(
        "NRM Performance Counts:",
        approvedNRMPerformance,
        rejectedNRMPerformance
      );

      let approvedErosion = 0;
      let rejectedErosion = 0;
      let approvedDataCollection = 0;
      let rejectedDataCollection = 0;
      let approvedCostSheet = 0;
      let rejectedCostSheet = 0;

      erosionProfileData.forEach((item) => {
        if (item?.submission_status === "approved") {
          approvedErosion++;
        } else if (item?.submission_status === "rejected") {
          rejectedErosion++;
        }
      });

      dataCollectionData.forEach((item) => {
        if (item?.submission_status === "approved") {
          approvedDataCollection++;
        } else if (item?.submission_status === "rejected") {
          rejectedDataCollection++;
        }
      });

      costSheetData.forEach((item) => {
        if (item?.submission_status === "approved") {
          approvedCostSheet++;
        } else if (item?.submission_status === "rejected") {
          rejectedCostSheet++;
        }
      });

      setErosionProfileCounts({
        approved: approvedErosion,
        rejected: rejectedErosion,
      });
      setDataCollectionCounts({
        approved: approvedDataCollection,
        rejected: rejectedDataCollection,
      });
      setCostSheetCounts({
        approved: approvedCostSheet,
        rejected: rejectedCostSheet,
      });

      console.log("Erosion Profile Counts:", approvedErosion, rejectedErosion);
      console.log(
        "Data Collection Counts:",
        approvedDataCollection,
        rejectedDataCollection
      );
      console.log("Cost Sheet Counts:", approvedCostSheet, rejectedCostSheet);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const getCategoryCounts = () => {
    const categories = [
      "Daily Trial Monitoring",
      "NRM Performance",
      "Erosion Profile",
      "Data Collection",
      "Cost Sheet",
    ];

    let counts = categories.reduce((acc, category) => {
      acc[category] = { approved: 0, rejected: 0 };
      return acc;
    }, {});

    counts["Erosion Profile"] = erosionProfileCounts;
    counts["Data Collection"] = dataCollectionCounts;
    counts["Cost Sheet"] = costSheetCounts;
    counts["Daily Trial Monitoring"] = dailyTrialCounts;
    counts["NRM Performance"] = nrmTrialCounts;

    events.forEach((event) => {
      if (counts[event.category]) {
        counts[event.category][event.status] += 1;
      }
    });

    return counts;
  };

  const categoryCounts = getCategoryCounts();

  return (
    <div className="w-full px-4">
      <div className="sm:mt-0 flex sm:block mt-4 justify-end min-w-fit rounded-md py-2">
        <div className="lg:flex justify-between items-center mb-0 py-2">
          <h1 className="w-1/2 text-xl lg:text-3xl font-semibold lg:mb-0 mb-4">
            Data Management Summary
          </h1>
          <div className=" flex items-center space-x-4 relative rounded-lg pl-1">
            <div className="w-1/2">
              <label className="text-black font-medium text-xs">Region</label>

              <select
                id="region"
                name="region"
                value={selectedRegion}
                onChange={(e) => {
                  const newRegion = e.target.value;
                  setSelectedRegion(newRegion);
                }}
                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              >
                <option value="">Select Region</option>
                {region.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.region_name}
                  </option>
                ))}
              </select>
            </div>

            <DateRangePicker
              startDate={selectedDates.startDate}
              endDate={selectedDates.endDate}
              onDateChange={handleDateChange}
            />
          </div>
        </div>
      </div>

      {/* Legend Header Box */}
      <div className="mb-0  lg:p-4 p-2  rounded-lg border">
        <div className="grid grid-cols-5 lg:gap-4 gap-1">
          {Object.keys(categoryCounts).map((category, index) => (
            <div
              key={category}
              className={`flex flex-col ${index !== Object.keys(categoryCounts).length - 1
                ? "border-r lg:px-4 pe-2"
                : ""
                }`}
            >
              <div className="mb-5 font-medium lg-text-sm text-xs">
                {category}
              </div>
              <div className="flex space-x-4 justify-between">
                <div className="flex flex-col items-center">
                  <span className="lg-text-5xl text-3xl mb-4 text-black-500 font-semibold">
                    {categoryCounts[category].approved}
                  </span>
                  <span className="lg-text-sm text-xs text-gray-600">
                    Approved
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <span className="lg-text-5xl text-3xl mb-4 text-black-500 font-semibold">
                    {categoryCounts[category].rejected}
                  </span>
                  <span className="lg-text-sm text-xs text-gray-600">
                    Rejected
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full p-4 border rounded-lg mt-4">
        <div className="flex justify-between items-center">
          {/* Left-aligned Header */}
          <h1 className="text-xl font-semibold text-gray-700">
            Data Trial Analysis
          </h1>

          {/* Toggle Switch */}
          <label className="inline-flex items-center cursor-pointer  relative p-1 h-10">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              onChange={handleToggle}
            />
            <div className="absolute left-0 top-0 overflow-hidden w-full rounded h-10 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0 after:left-0 after:rounded after:bg-base-bg after:h-full after:w-1/2 after:transition-all after:z-0"></div>

            <span
              className={`relative flex items-center z-1 mx-3 text-sm font-semibold ${isCalendarView ? "text-black" : "text-gray-900"
                } dark:text-gray-300`}
            >
              <img src={TableIcon} alt="table" /> Graph
            </span>
            <span
              className={`relative flex items-center z-1 mx-3 text-sm font-semibold ${!isCalendarView ? "text-black" : "text-gray-900"
                } dark:text-gray-300`}
            >
              <img src={Board} className="ms-4" alt="board" /> Calendar
            </span>
          </label>
        </div>
        <div>
          {!isCalendarView ? (
            <CalendarView
              selectedRegion={selectedRegion}
              // userId={userId}
              selectedDates={selectedDates}
            />
          ) : (
            <StackedBarChart
              selectedRegion={selectedRegion}
              // userId={userId}
              selectedDates={selectedDates}
            />
          )}
        </div>
      </div>
    </div>
  );
}
