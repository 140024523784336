import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { savings_metallurgical_benefit, trial_name, savings_metallurgical_benefit_nrm, materialType } from "../../../recoil/atom";
import Helper from "../../../service/Helper";
const MetallurgicalBenefits = () => {
  const [metallurgicalBenefits, setMetallurgicalBenefits] = useRecoilState(
    savings_metallurgical_benefit
  )
  const [type, setType] = useRecoilState(materialType)
  const [metallurgicalBenefitsNRM, setMetallurgicalBenefitsNRM] =
    useRecoilState(savings_metallurgical_benefit_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const handleMetallurgicalBenefitsNRM = (name, value) => {
    setMetallurgicalBenefitsNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S8 : Savings due to other metallurgical benefits
      </h2>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings due to Metallurgical Benefit (F6) (dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value="NA"
            onChange={(event) =>
              handleMetallurgicalBenefitsNRM(
                'savings_metallurgical',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="savings_metallurgical"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings due to Metallurgical Benefit (F6) (dia / MT)
          </label>
          <input
            type="text"
            id="savings_metallurgical"
            name="savings_metallurgical"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={metallurgicalBenefitsNRM.savings_metallurgical}
            onChange={(event) =>
              handleMetallurgicalBenefitsNRM(
                'savings_metallurgical',
                event.target.value
              )
            }
          />
        </div>
      </div>


    </>
  );
};

export default MetallurgicalBenefits;
