import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { cost_calculation, cost_calculation_nrm, trial_name, materialType } from "../../../recoil/atom";
import {
  costCalculation, costCalculationNRM
} from '../../../recoil/selector'
import Helper from "../../../service/Helper";
const CostCalculation = () => {
  const [CostCalculation, setCostCalculation] = useRecoilState(cost_calculation)
  const [CostCalculationNRM, setCostCalculationNRM] =
    useRecoilState(cost_calculation_nrm)
  const [type, setType] = useRecoilState(materialType)

  const costCalculatedvalues = useRecoilValue(costCalculation)
  const costCalculatedvaluesNRM = useRecoilValue(costCalculationNRM)
  console.log(costCalculatedvalues, 'costCalculatedvalues')
  console.log(costCalculatedvaluesNRM, 'costCalculatedvaluesNRM')

  const [trialName] = useRecoilState(trial_name)

  const handleCostCalculationsChange = (name, value) => {
    setCostCalculation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCostCalculationsNRMChange = (name, value) => {
    setCostCalculationNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };


  return (
    <div
      style={{ height: "320px" }}
      className="mt-4 w-full overflow-y-auto pb-4 pr-4"
    >
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Cost Calculations
      </h2>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <div className="w-full mb-4">
          <label
            htmlFor="avg_in_heat_size"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size in a campaign (MT)
          </label>
          <input
            type="text"
            id="avg_in_heat_size"
            name="avg_in_heat_size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageHeatSize}
            onChange={(event) =>
              handleCostCalculationsChange('avg_in_heat_size', event.target.value)
            }
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average life in a campaign (Heats)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageLife}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total No of cycle in a campaign (each)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.totNoOfCycle}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full mb-4">
          <label
            htmlFor="no_of_cycles"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total no of heats in a campaign (Heats)
          </label>
          <input
            type="text"
            id="no_of_cycles"
            name="no_of_cycles"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.totNoOfHeats}
            onChange={(event) =>
              handleCostCalculationsChange('no_of_cycles', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Production per Campaign (MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.totProduction}
            onChange={(event) =>
              handleCostCalculationsChange('tot_prod', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material Landed cost - 1 (dia / Kg)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.ramming_material_landed_cost_1}
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_material_landed_cost_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material cost In a Campaign - 1 (dia)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={
            costCalculatedvalues.rammingMaterialCost_1
              ? costCalculatedvalues.rammingMaterialCost_1
              : 0
          }
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_cost_in_campaign_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 2 (dia / Kg)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.topping_material_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 2 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_2"
          name="cost_in_campaign_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.toppingMaterialCost_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="topping_material_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 3 (dia / MT)
        </label>
        <input
          type="text"
          id="topping_material_3"
          name="topping_material_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.topping_material_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="cost_in_campaign_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 3 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_3"
          name="cost_in_campaign_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.toppingMaterialCost_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_cost_in_campaign"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total cost in a campaign (Refractory) (dia /MT)
        </label>
        <input
          type="text"
          id="tot_cost_in_campaign"
          name="tot_cost_in_campaign"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.totCostCampaignRefractory}
          onChange={(event) =>
            handleCostCalculationsChange(
              'tot_cost_in_campaign',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_refractory"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total Refractory Consumption (Kg)
        </label>
        <input
          type="text"
          id="tot_refractory"
          name="tot_refractory"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.totRefractoryConsumption}
          onChange={(event) =>
            handleCostCalculationsChange('tot_refractory', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Specific Refractory Consumption (kg/T of steel) (Kg / MT)
        </label>
        <input
          type="text"
          id="spec_refractory_consumpt"
          name="spec_refractory_consumpt"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.SpecificRefractoryConsumpt}
          onChange={(event) =>
            handleCostCalculationsChange(
              'spec_refractory_consumpt',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Cost of refractory per ton of steel produced (dia / MT)
        </label>
        <input
          type="text"
          id="costOfrefractory"
          name="costOfrefractory"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={
            costCalculatedvalues.costOfrefractory
              ? costCalculatedvalues.costOfrefractory
              : 0
          }
          onChange={(event) =>
            handleCostCalculationsChange(
              'costOfrefractory',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Increase in cost due to NRM (INR/MT)
        </label>
        <input
          type="text"
          id="spec_refractory_consumpt"
          name="spec_refractory_consumpt"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value='NA'
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total Savings due to NRM (S1 to S8) (INR/MT)
        </label>
        <input
          type="text"
          id="spec_refractory_consumpt"
          name="spec_refractory_consumpt"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value='NA'
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Relative Gain in cost due to NRM (INR/MT)
        </label>
        <input
          type="text"
          id="spec_refractory_consumpt"
          name="spec_refractory_consumpt"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value='NA'
        />
      </div>

      <h2 className="text-l font-medium text-gray-800 mb-4 uppercase">
        NRM
      </h2>

      <div className="mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="avg_in_heat_size"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size in a campaign (MT)
          </label>
          <input
            type="text"
            id="avg_in_heat_size"
            name="avg_in_heat_size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvaluesNRM.averageHeatSizeNRM}
            onChange={(event) =>
              handleCostCalculationsChange('avg_in_heat_size', event.target.value)
            }
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average life in a campaign (Heats)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvaluesNRM.averageLifeNRM}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total No of cycle in a campaign (each)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvaluesNRM.totNoOfCycleNRM}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="no_of_cycles"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total no of heats in a campaign (Heats)
          </label>
          <input
            type="text"
            id="no_of_cycles"
            name="no_of_cycles"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvaluesNRM.totNoOfHeatsNRM}
            onChange={(event) =>
              handleCostCalculationsChange('no_of_cycles', event.target.value)
            }
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Production per Campaign (MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvaluesNRM.totProductionNRM}
            onChange={(event) =>
              handleCostCalculationsChange('tot_prod', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material Landed cost - 1 (dia / Kg)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculationNRM.ramming_material_landed_cost_1}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'ramming_material_landed_cost_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material cost In a Campaign - 1 (dia)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={
            costCalculatedvaluesNRM.rammingMaterialCostNRM_1
              ? costCalculatedvaluesNRM.rammingMaterialCostNRM_1
              : 0
          }
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_cost_in_campaign_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="topping_material_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 2 (dia / Kg)
        </label>
        <input
          type="text"
          id="topping_material_2"
          name="topping_material_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculationNRM.topping_material_2}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'topping_material_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 2 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_2"
          name="cost_in_campaign_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvaluesNRM.toppingMaterialCostNRM_2}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'cost_in_campaign_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="topping_material_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 3 (dia / MT)
        </label>
        <input
          type="text"
          id="topping_material_3"
          name="topping_material_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculationNRM.topping_material_3}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'topping_material_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="cost_in_campaign_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 3 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_3"
          name="cost_in_campaign_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvaluesNRM.toppingMaterialCostNRM_3}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'cost_in_campaign_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_cost_in_campaign"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total cost in a campaign (Refractory) (dia /MT)
        </label>
        <input
          type="text"
          id="tot_cost_in_campaign"
          name="tot_cost_in_campaign"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvaluesNRM.totCostCampaignRefractoryNRM}
          onChange={(event) =>
            handleCostCalculationsChange(
              'tot_cost_in_campaign',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_refractory"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total Refractory Consumption (Kg)
        </label>
        <input
          type="text"
          id="tot_refractory"
          name="tot_refractory"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvaluesNRM.totRefractoryConsumptionNRM}
          onChange={(event) =>
            handleCostCalculationsChange('tot_refractory', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Specific Refractory Consumption (kg/T of steel) (Kg / MT)
        </label>
        <input
          type="text"
          id="spec_refractory_consumpt"
          name="spec_refractory_consumpt"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvaluesNRM.SpecificRefractoryConsumptNRM}
          onChange={(event) =>
            handleCostCalculationsChange(
              'spec_refractory_consumpt',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="costOfrefractoryNRM"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Cost of refractory per ton of steel produced (dia / MT)
        </label>
        <input
          type="text"
          id="costOfrefractoryNRM"
          name="costOfrefractoryNRM"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvaluesNRM.costOfrefractoryNRM}
          onChange={(event) =>
            handleCostCalculationsChange(
              'costOfrefractoryNRM',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="costOfrefractoryNRM"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Increase in cost due to NRM (dia / MT)
        </label>
        <input
          type="text"
          id="costOfrefractoryNRM"
          name="costOfrefractoryNRM"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvaluesNRM.increaseInCostDueToNRM ? costCalculatedvaluesNRM.increaseInCostDueToNRM : "0.00"}
          onChange={(event) =>
            handleCostCalculationsChange(
              'costOfrefractoryNRM',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="costOfrefractoryNRM"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total Savings due to NRM (S1 to S9) - (INR/MT)
        </label>
        <input
          type="text"
          id="costOfrefractoryNRM"
          name="costOfrefractoryNRM"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
        // value={costCalculatedvaluesNRM.increaseInCostDueToNRM ? costCalculatedvaluesNRM.increaseInCostDueToNRM : "0.00"}
        // onChange={(event) =>
        //   handleCostCalculationsChange(
        //     'costOfrefractoryNRM',
        //     event.target.value
        //   )
        // }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="costOfrefractoryNRM"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Relative Gain in cost due to NRM - (INR/MT)
        </label>
        <input
          type="text"
          id="costOfrefractoryNRM"
          name="costOfrefractoryNRM"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
        // value={costCalculatedvaluesNRM.increaseInCostDueToNRM ? costCalculatedvaluesNRM.increaseInCostDueToNRM : "0.00"}
        // onChange={(event) =>
        //   handleCostCalculationsChange(
        //     'costOfrefractoryNRM',
        //     event.target.value
        //   )
        // }
        />
      </div>
    </div>
  );
};

export default CostCalculation;
