import React, { useState, useEffect } from "react";
import ModalLayout from "../Common/ModalLayout";
import API from "./../../service/API";
import axios from "axios";
import AlertDialog from "./../Common/AlertDialog";
import Helper from "../../service/Helper";
import DailyTrialLayout from "./DailyTrialLayout";
import NRMLayout from "../NRMForm/NRMLayout";
import DataCollectionLayout from "../DataCollectionForm/DataCollectionLayout";
import { TerminalSharp } from "@mui/icons-material";
import ErosionProfileLayout from "../ErosionProfileForm/ErosionProfileLayout";
import CostSheetLayout from "../CostsheetForm/CostSheetLayout";
import { useResetRecoilState } from "recoil";
import {
  general_details,
  furnace_details,
  charge_mix_details,
  carbon,
  alloying_addition,
  other_parameters,
} from "../../recoil/atom";
import {
  lining_reference,
  production_summary,
  lining_material_product_details,
  lining_material_consumption,
  dimensional_parameters,
  sintering_parameters,
} from "../../recoil/atom";
import { tr } from "date-fns/locale";

const AddFormUpload = ({ closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
  const [customers, setCustomers] = useState([]);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [segments, setSegments] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [furnaces, setFurnaces] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [DailyTrial, setDailyTrial] = useState(false);
  const [Nrm, setNrm] = useState(false);
  const [DataCollection, setDataCollection] = useState(false);
  const [ErosionProfile, setErosionProfile] = useState(false);
  const [CostSheet, setCostSheet] = useState(false);
  const [SegmentList, setSegmentList] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    segment_id: "",
    customer_id: "",
    template_id: "",
    furnace_id: "",
  });
  const [blobtemplates, setBlobTemplates] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  // filtered usestates
  const [filteredDailyTrials, setFilteredDailyTrials] = useState([]);
  console.log(filteredDailyTrials, "filteredDailyTrials");

  const storedCustomerDet = JSON.parse(
    localStorage.getItem("selectedCustomer")
  );
  const access_token = localStorage.getItem("accesstoken");
  const [selectCustomer, setSelectCustomer] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [modalTitle, setModalTitle] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false); // For the new popup
  const resetGeneralDetailsValue = useResetRecoilState(general_details);
  const resetFurnaceDetailsValue = useResetRecoilState(furnace_details);
  const resetChargeMixDetailsValue = useResetRecoilState(charge_mix_details);
  const resetCarbonValue = useResetRecoilState(carbon);
  const resetAlloyingadditionValue = useResetRecoilState(alloying_addition);
  const resetOtherParametersValue = useResetRecoilState(other_parameters);
  const resetLiningReferenceValue = useResetRecoilState(lining_reference);
  const resetProductionSummaryValue = useResetRecoilState(production_summary);

  const resetLiningMaterialProductDetailsValue = useResetRecoilState(
    lining_material_product_details
  );
  const resetLiningMaterialConsumptionValue = useResetRecoilState(
    lining_material_consumption
  );
  const resetDimensionalParametersValue = useResetRecoilState(
    dimensional_parameters
  );
  const resetSinteringParametersValue =
    useResetRecoilState(sintering_parameters);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "customer_id") {
      setFormData({ ...formData, [name]: value });
      await fetchFurnaceData(value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFormCancel = async () => {
    closeModal(false);
  };

  const matchingTemplate = templates.find(
    (template) => String(template.id) === String(formData.template_id)
  );

  const resetNRMValues = async () => {
    setNrm(true);
    setDailyTrial(false);
    setDataCollection(false);
    setErosionProfile(false);
    resetLiningReferenceValue();
    resetProductionSummaryValue();
    resetLiningMaterialProductDetailsValue();
    resetLiningMaterialConsumptionValue();
    resetDimensionalParametersValue();
    resetSinteringParametersValue();
  };

  const resetDailyTrialValues = async () => {
    setDailyTrial(true);
    setNrm(false);
    setDataCollection(false);
    setErosionProfile(false);
    resetGeneralDetailsValue();
    resetFurnaceDetailsValue();
    resetChargeMixDetailsValue();
    resetCarbonValue();
    resetAlloyingadditionValue();
    resetOtherParametersValue();
  };



  const handleFormSubmit = async () => {

    // Perform validation
    const errors = {};
    if (!formData.template_id) {
      errors.template_id = "Please select a Data File Template.";
    }
    if (
      matchingTemplate &&
      matchingTemplate.template_id !== 4 &&
      matchingTemplate.template_id !== 5 &&
      !formData.segment_id
    ) {
      errors.segment_id = "Please select a Segment.";
    }
    if (!formData.customer_id) {
      errors.customer_id = "Please select a Customer Name.";
    }
    if (
      matchingTemplate &&
      matchingTemplate.template_id !== 4 &&
      matchingTemplate.template_id !== 5 &&
      !formData.furnace_id
    ) {
      errors.furnace_id = "Please select a Furnace.";
    }

    // If there are errors, set them in state and stop execution
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setFormErrors({});

    // Retrieve the name or template_id
    if (matchingTemplate) {
      console.log("Template Name12:", matchingTemplate.name);
      console.log("Template ID12:", matchingTemplate.template_id);
      if (matchingTemplate.template_id == 1) {
        await resetDailyTrialValues();
      } else if (matchingTemplate.template_id == 2) {
        await resetNRMValues();
      } else if (matchingTemplate.template_id == 4) {
        setDataCollection(true);
        setDailyTrial(false);
        setNrm(false);
        setErosionProfile(false);
      } else if (matchingTemplate.template_id == 3) {
        setErosionProfile(true);
        setDataCollection(false);
        setDailyTrial(false);
        setNrm(false);
      } else if (matchingTemplate.template_id == 5) {
        setCostSheet(true);
        setDataCollection(false);
        setDailyTrial(false);
        setNrm(false);
        setErosionProfile(false);
      }
    } else {
      console.log("No matching template found.");
    }

    if (matchingTemplate) {
      const jsonDataWithUserId = {
        ...formData,
        user_id: currentUser.id,
      };
      let response;
      try {
        if (
          matchingTemplate.template_id === 4 ||
          matchingTemplate.template_id === 5
        ) {
          response = await axios.post(
            API.UPLOAD_DATA_COLLECTION_DATAFILE,
            jsonDataWithUserId,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
        } else {
          response = await axios.post(
            API.UPLOAD_DATAFILE_MOBILE,
            jsonDataWithUserId,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
        }

        if (response.status === 200) {
          const responseData = response.data;
          const dataFileResponse = responseData.data;
          localStorage.setItem(
            "dataFileResponse",
            JSON.stringify(dataFileResponse)
          );
          const datafile_id = responseData.data.id;
          console.log("datafile_id", datafile_id);
          localStorage.setItem("datafile_id", datafile_id);
          localStorage.setItem("Trial", "new");
          const templateId = responseData.data.template.template_id;
          console.log("templateId", templateId);
          console.log("responseData", responseData);
        } else {
          console.log("Error: Data file update failed.");
        }
      } catch (error) {
        console.error("Error during API request:", error);
      }
    } else {
      console.log("No matching template found.");
    }

    // Optional: Close any dialogs or perform other UI updates
    // setIsDialogOpen(false);
  };


  const fetchTemplates = async () => {
    try {
      const response = await axios.get(API.GET_TEMPLATES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log("responsetemplates", response);
      setTemplates(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const selectedTemplate = templates.find(
    (template) => template.id === formData.template_id
  );

  console.log(selectedTemplate, "hehehe");

  console.log("selectedtemaplate", selectedTemplate);
  const fetchSegments = async () => {
    try {
      const response = await axios.get(API.GET_SEGMENT, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setSegments(response.data);
    } catch (error) {
      console.error("Error fetching lining:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchTemplates();
    fetchSegments();
    fetchFurnaceData();
  }, []);

  const fetchFurnaceData = async (value) => {
    try {
      const response = await axios.get(API.GET_FURNACES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const matchingFurnaces = response.data.filter(
        (furnace) => furnace.assign_customer_id === value
      );
      if (matchingFurnaces) {
        const matchingFurnaceArr = matchingFurnaces;
        setFurnaces(matchingFurnaceArr);
      } else {
        setFurnaces([]);
        console.log("No matching furnace found");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const getCustomerName = async () => {
    const getCustomerDetails = customers.find(
      (customer) => customer.id === formData.customer_id
    );
    const customerName = getCustomerDetails.organization_name;
    const trigram = Helper.getCutomerTrigram(customerName);
    return trigram;
  };
  const handleFileUploads = (e) => {
    e.preventDefault();
    const uploadedFiles = e.target.files;
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

    console.log("Uploaded Files:", uploadedFiles);

    if (uploadedFiles.length === 0) {
      setFile(null);
      return;
    }

    const uploadedFile = uploadedFiles[0];
    console.log("Uploaded File:", uploadedFile);

    // Check if the file size exceeds the maximum limit
    if (uploadedFile.size > maxFileSize) {
      alert("File size exceeds 2MB limit.");
      setFile(null); // Clear the file if it exceeds the limit
      return;
    }

    // Proceed with setting the file
    setFile(uploadedFile);
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const customerResponse = response.data;

      // For role 1
      if (currentUser.role.role_id === 1) {
        const customerId = currentUser.assign_customer_id[0];
        const customers = customerResponse.filter(
          (item) => item.id === customerId
        );
        setCustomers(customers);

        const customer_id = storedCustomerDet.id;
        const selectedCustomer = customerResponse.filter(
          (item) => item.id === customer_id
        );
        setSelectCustomers(selectedCustomer);

        // For roles 2 and 3
      } else if (
        currentUser.role.role_id === 2 ||
        currentUser.role.role_id === 3
      ) {
        const assignCustomerIds = currentUser.assign_customer_id.map(
          (id) => id
        );
        const filteredCustomers = customerResponse.filter((customer) =>
          assignCustomerIds.includes(customer.id)
        );
        setCustomers(filteredCustomers);

        const customer_id = storedCustomerDet.id;
        const selectedCustomer = filteredCustomers.filter(
          (item) => item.id === customer_id
        );
        setSelectCustomers(selectedCustomer);

        // For other roles (e.g., 4, 5, etc.)
      } else {
        setCustomers(customerResponse);

        const customer_id = storedCustomerDet.id;
        const selectedCustomer = customerResponse.filter(
          (item) => item.id === customer_id
        );
        setSelectCustomers(selectedCustomer);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUploadClick = () => {
    setIsModalOpen(true); // Open the modal
  };


  return (
    <>

      {!DailyTrial && !Nrm && !DataCollection && !ErosionProfile && !CostSheet && (
        <form className="space-y-5 w-full pr-4">
          <div className="w-full" style={{ maxHeight: "460px", minHeight: "430px" }}>
            <div className="w-full">
              <label htmlFor="template_id" className="block font-semibold mb-1">
                Data File Template *
              </label>
              <select
                id="template_id"
                name="template_id"
                value={formData.template_id}
                onChange={handleChange}
                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              >
                <option value="">Select a template</option>
                {templates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </select>
              {formErrors.template_id && (
                <p className="text-red-500 text-xs mt-1">{formErrors.template_id}</p>
              )}
            </div>

            {matchingTemplate && matchingTemplate.template_id !== 4 && matchingTemplate.template_id !== 5 && (
              <div className="w-full mt-4">
                <label htmlFor="segment_id" className="block font-semibold mb-1">
                  Segment *
                </label>
                <select
                  id="segment_id"
                  name="segment_id"
                  value={formData.segment_id}
                  onChange={handleChange}
                  className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                >
                  <option value="">Select a segment</option>
                  {segments.map((segment) => (
                    <option key={segment.id} value={segment.id}>
                      {segment.name}
                    </option>
                  ))}
                </select>
                {formErrors.segment_id && (
                  <p className="text-red-500 text-xs mt-1">{formErrors.segment_id}</p>
                )}
              </div>
            )}

            <div className="w-full mt-4">
              <label htmlFor="customer_id" className="block font-semibold mb-1">
                Customer Name *
              </label>
              <select
                id="customer_id"
                name="customer_id"
                value={formData.customer_id}
                onChange={handleChange}
                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              >
                <option value="">Select a customer</option>
                {selectCustomers.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.organization_name}
                  </option>
                ))}
              </select>
              {formErrors.customer_id && (
                <p className="text-red-500 text-xs mt-1">{formErrors.customer_id}</p>
              )}
            </div>

            {matchingTemplate && matchingTemplate.template_id !== 4 && matchingTemplate.template_id !== 5 && (
              <div className="w-full mt-4">
                <label htmlFor="furnace_id" className="block font-semibold mb-1">
                  Furnace Number - Capacity *
                </label>
                <select
                  id="furnace_id"
                  name="furnace_id"
                  value={formData.furnace_id}
                  onChange={handleChange}
                  className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                >
                  <option value="">Select a furnace</option>
                  {furnaces.map((furnace) => (
                    <option key={furnace.id} value={furnace.id}>
                      {`${furnace.furnace_number} - ${furnace.capacity}`}
                    </option>
                  ))}
                </select>
                {formErrors.furnace_id && (
                  <p className="text-red-500 text-xs mt-1">{formErrors.furnace_id}</p>
                )}
              </div>
            )}
          </div>
          <div className="w-full flex justify-center items-center bg-white">
            <button
              type="button"
              className="w-1/2 h-10 px-3 py-2 rounded-md text-gray-800 border border-gray-300 text-sm font-semibold mr-2.5"
              onClick={handleFormCancel}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="w-1/2 h-10 px-3 py-2 rounded-md text-white bg-custom-red text-sm font-semibold"
              onClick={handleFormSubmit}
            >
              SUBMIT
            </button>
          </div>
        </form>
      )}

      {DailyTrial && <DailyTrialLayout closeModal={closeModal} />}
      {Nrm && <NRMLayout closeModal={closeModal} />}
      {DataCollection && <DataCollectionLayout closeModal={closeModal} />}
      {ErosionProfile && <ErosionProfileLayout closeModal={closeModal} />}
      {CostSheet && <CostSheetLayout closeModal={closeModal} />}
    </>
  );

};

export default AddFormUpload;
