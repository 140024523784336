import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { labor_cost, labor_cost_nrm, trial_name, new_lining_details, materialType } from "../../../recoil/atom";
import { laborCostCampaignSelector, laborCostPerTonSelector, laborCostCampaignNRMSelector, laborCostPerTonNRMSelector, savingInLaborCostNRMSelector, totalSavingsSelector } from "../../../recoil/selector"
import Helper from "../../../service/Helper";
const LaborCost = () => {
  const [laborCost, setLaborCost] = useRecoilState(labor_cost)
  const [laborCostNRM, setLaborCostNRM] = useRecoilState(labor_cost_nrm)
  const [trialName] = useRecoilState(trial_name)
  const [type, setType] = useRecoilState(materialType)

  const laborCostCampaign = useRecoilValue(laborCostCampaignSelector)
  const laborCostPerTon = useRecoilValue(laborCostPerTonSelector)
  const laborCostCampaignNRM = useRecoilValue(laborCostCampaignNRMSelector)
  const laborCostPerTonNRM = useRecoilValue(laborCostPerTonNRMSelector)
  const SavingInLaborCostNRM = useRecoilValue(savingInLaborCostNRMSelector)
  const totalSavings = useRecoilValue(totalSavingsSelector)
  console.log(totalSavings, "totalSavings90")
  console.log(laborCostCampaign, 'laborCostCampaign')

  const [newLining] = useRecoilState(new_lining_details)

  const [laborCostObject, setLaborCostObject] = useState([])
  console.log(newLining, 'newLining')

  const handleLaborCostChange = (name, value) => {
    setLaborCost((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleLaborCostNRMChange = (name, value) => {
    setLaborCostNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S1 : Savings in Labor Cost
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="labor_cost_per_nl"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Labor cost per NL / SL / PA - (dia)
          </label>
          <input
            type="text"
            id="labor_cost_per_nl"
            name="labor_cost_per_nl"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Labor cost"
            value={laborCost.labor_cost_per_nl}
            onChange={(event) =>
              handleLaborCostChange('labor_cost_per_nl', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Labor cost in a campaign - (dia)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Labor cost"
            value={laborCostCampaign ? laborCostCampaign : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Labor cost per ton of steel produced - <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Labor cost"
            value={laborCostPerTon ? laborCostPerTon : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings In Labor Cost - <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Savings"
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>

      <h2 className="text-l font-medium text-black-400 mb-6 uppercase">NRM</h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="labor_cost_per_nl"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Labor cost per NL / SL / PA - (dia)
          </label>
          <input
            type="text"
            id="labor_cost_per_nl"
            name="labor_cost_per_nl"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cost"
            value={laborCostNRM.labor_cost_per_nl}
            onChange={(event) =>
              handleLaborCostNRMChange('labor_cost_per_nl', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="laborCostCampaignNRM"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Labor cost in a campaign - (dia)
          </label>
          <input
            type="text"
            id="laborCostCampaignNRM"
            name="laborCostCampaignNRM"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cost"
            value={laborCostCampaignNRM ? laborCostCampaignNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Labor cost per ton of steel produced -  <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cost"
            value={laborCostPerTonNRM ? laborCostPerTonNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings In Labor Cost - <br />
            (dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cost"
            value={SavingInLaborCostNRM ? SavingInLaborCostNRM : 0}
            onChange={() => { }}
          />
        </div>
      </div>
    </>
  );
};

export default LaborCost;
