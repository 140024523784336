import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { new_lining_details_nrm, new_lining_details, trial_name, materialType } from "../../../recoil/atom";
import Helper from '../.././../service/Helper'

const NewLiningDetails = () => {
  const [newLining, setNewLining] = useRecoilState(new_lining_details)
  const [newLiningNRM, setNewLiningNRM] = useRecoilState(new_lining_details_nrm)
  console.log(newLining, "newLining34")
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const [type, setType] = useRecoilState(materialType)

  const handleNewLiningChange = (name, value, isDropDown) => {
    const regex = /^[a-zA-Z0-9]*\.?[0-9]{0,2}$/;
    if (regex.test(value) || value === '' || isDropDown) {
      console.log(value, "valueee")
      setNewLining((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const handleNewLiningNRMChange = (name, value, isDropDown) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '' || isDropDown) {
      setNewLiningNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };

  useEffect(() => {
    localStorage.setItem('newLining', JSON.stringify(newLining))
    localStorage.setItem('newLiningNRM', JSON.stringify(newLiningNRM))
  }, [newLining, newLiningNRM])

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        New Lining Reference(B)
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_new_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in new lining - 1
        </label>
        <select
          id="ramming_material_new_lining_1"
          name="ramming_material_new_lining_1"
          value={newLining.ramming_material_new_lining_1}
          onChange={(event) =>
            handleNewLiningChange(
              'ramming_material_new_lining_1',
              event.target.value, true
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="qty_new_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for new lining - 1 (Kg)
        </label>
        <input
          type="text"
          id="qty_new_lining_1"
          name="qty_new_lining_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={newLining.qty_new_lining_1}
          onChange={(event) =>
            handleNewLiningChange('qty_new_lining_1', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={newLining.material_used_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningChange(
              'material_used_spout_and_top_2',
              event.target.value, true
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (Kg)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={newLining.qty_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningChange('qty_spout_and_top_2', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_spout_and_top_3"
          name="material_used_spout_and_top_3"
          value={newLining.material_used_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningChange(
              'material_used_spout_and_top_3',
              event.target.value, true
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a campaign</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3 (Kg)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLining.qty_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningChange('qty_spout_and_top_3', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="average_new_lining_life"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Average New Lining Life (Heat / Lining)
        </label>
        <input
          type="text"
          id="average_new_lining_life"
          name="average_new_lining_life"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Average New Lining Life"
          value={newLining.average_new_lining_life}
          onChange={(event) =>
            handleNewLiningChange('average_new_lining_life', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="no_of_new_lining"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          No of new lining (No)
        </label>
        <input
          type="text"
          id="no_of_new_lining"
          name="no_of_new_lining"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Nos "
          value={newLining.no_of_new_lining}
          onChange={(event) =>
            handleNewLiningChange('no_of_new_lining', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="avg_heat_size"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Average heat size per New lining (MT)
        </label>
        <input
          type="text"
          id="avg_heat_size"
          name="avg_heat_size"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLining.avg_heat_size}
          onChange={(event) =>
            handleNewLiningChange('avg_heat_size', event.target.value)
          }
        />
      </div>

      {/* NRM */}
      <h1 className="text-l font-medium text-gray-800 mb-4 uppercase">
        NRM
      </h1>

      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_new_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in new lining - 1
        </label>
        <select
          id="ramming_material_new_lining_1"
          name="ramming_material_new_lining_1"
          value={newLiningNRM.ramming_material_new_lining_1}
          onChange={(event) =>
            handleNewLiningNRMChange(
              'ramming_material_new_lining_1',
              event.target.value, true
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="qty_new_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for new lining - 1 (Kg)
        </label>
        <input
          type="text"
          id="qty_new_lining_1"
          name="qty_new_lining_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLiningNRM.qty_new_lining_1}
          onChange={(event) =>
            handleNewLiningNRMChange('qty_new_lining_1', event.target.value)
          }
        />
      </div>


      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={newLiningNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningNRMChange(
              'material_used_spout_and_top_2',
              event.target.value, true
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a campaign</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (Kg)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLiningNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningNRMChange('qty_spout_and_top_2', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_spout_and_top_3"
          name="material_used_spout_and_top_3"
          value={newLiningNRM.material_used_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningNRMChange(
              'material_used_spout_and_top_3',
              event.target.value, true
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a campaign</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3 (Kg)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLiningNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningNRMChange('qty_spout_and_top_3', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="average_new_lining_life"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Average New Lining Life (Heat / Lining)
        </label>
        <input
          type="text"
          id="average_new_lining_life"
          name="average_new_lining_life"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLiningNRM.average_new_lining_life}
          onChange={(event) =>
            handleNewLiningNRMChange('average_new_lining_life', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="no_of_new_lining"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          No of new lining (No)
        </label>
        <input
          type="text"
          id="no_of_new_lining"
          name="no_of_new_lining"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLiningNRM.no_of_new_lining}
          onChange={(event) =>
            handleNewLiningNRMChange('no_of_new_lining', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="avg_heat_size"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Average heat size per New Lining (MT)
        </label>
        <input
          type="text"
          id="avg_heat_size"
          name="avg_heat_size"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={newLiningNRM.avg_heat_size}
          onChange={(event) =>
            handleNewLiningNRMChange('avg_heat_size', event.target.value)
          }
        />
      </div>

    </>
  );
};

export default NewLiningDetails;
