import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { sintering_heats, sintering_heats_nrm, materialType } from "../../../recoil/atom";
import {
  chargedSinteringPerMonthSelector,
  costSinteringHeatPerMonthSelector,
  costOfReplacingWithDRISelector,
  costOfchargedSinteringHeatPerMTSelector,
  chargedSinteringPerMonthNRMSelector,
  costSinteringHeatPerMonthNRMSelector,
  costOfReplacingWithDRINRMSelector,
  costOfchargedSinteringHeatPerMTNRMSelector,
  scrapCostSavings
} from '../../../recoil/selector'
import Helper from "../../../service/Helper";
const SinteringHeats = () => {
  const [sinteringHeats, setSinteringHeats] = useRecoilState(sintering_heats)
  const [sinteringHeatsNRM, setSinteringHeatsNRM] =
    useRecoilState(sintering_heats_nrm)
  const [type, setType] = useRecoilState(materialType)

  const handleSinteringHeatChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/

    if (regex.test(value) || value === '') {
      setSinteringHeats((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleSinteringHeatNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/

    if (regex.test(value) || value === '') {
      setSinteringHeatsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const chargedSinteringPerMonth = useRecoilValue(
    chargedSinteringPerMonthSelector
  )
  console.log(chargedSinteringPerMonth, 'chargedSinteringPerMonth')
  const costSinteringHeatPerMonth = useRecoilValue(
    costSinteringHeatPerMonthSelector
  )
  const costOfReplacingWithDRI = useRecoilValue(costOfReplacingWithDRISelector)
  const costOfchargedSinteringHeatPerMT = useRecoilValue(
    costOfchargedSinteringHeatPerMTSelector
  )
  // nrm
  const chargedSinteringPerMonthNRM = useRecoilValue(
    chargedSinteringPerMonthNRMSelector
  )
  const costSinteringHeatPerMonthNRM = useRecoilValue(
    costSinteringHeatPerMonthNRMSelector
  )
  const costOfReplacingWithDRINRM = useRecoilValue(
    costOfReplacingWithDRINRMSelector
  )
  const costOfchargedSinteringHeatPerMTNRM = useRecoilValue(
    costOfchargedSinteringHeatPerMTNRMSelector
  )

  const scrapCostSavingNRM = useRecoilValue(scrapCostSavings)
  console.log(scrapCostSavingNRM, "scrapCostSavingNRM34")

  const handleMaterialTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S9 : Savings in scrap due to less number of sintering heats
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material Type
        </label>
        <select
          id="parameter"
          name="parameter"
          value={type}
          onChange={handleMaterialTypeChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material Type</option>
          {Helper.Parameter.map((Parameter) => (
            <option key={Parameter.id} value={Parameter.name}>
              {Parameter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="charge_mix_regular_heat"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield of overall charge mix in regular heat (%)
          </label>
          <input
            type="text"
            id="charge_mix_regular_heat"
            name="charge_mix_regular_heat"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeats.charge_mix_regular_heat}
            onChange={(event) =>
              handleSinteringHeatChange(
                'charge_mix_regular_heat',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="yield_of_sintering_scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield of Sintering scrap (%)
          </label>
          <input
            type="text"
            id="yield_of_sintering_scrap"
            name="yield_of_sintering_scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeats.yield_of_sintering_scrap}
            onChange={(event) =>
              handleSinteringHeatChange(
                'yield_of_sintering_scrap',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="market_price_sintering_scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market Price of Sintering scrap/MT (Rs./MT)
          </label>
          <input
            type="text"
            id="market_price_sintering_scrap"
            name="market_price_sintering_scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeats.market_price_sintering_scrap}
            onChange={(event) =>
              handleSinteringHeatChange(
                'market_price_sintering_scrap',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="percentage_scrap_addition"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Percentage Scrap addition difference between sintering and regular
            heat (%)
          </label>
          <input
            type="text"
            id="percentage_scrap_addition"
            name="percentage_scrap_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeats.percentage_scrap_addition}
            onChange={(event) =>
              handleSinteringHeatChange(
                'percentage_scrap_addition',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="excess_scrap_charged_per_sinteringHeat"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Excess Scrap charged per Sintering heat (MT)
          </label>
          <input
            type="text"
            id="excess_scrap_charged_per_sinteringHeat"
            name="excess_scrap_charged_per_sinteringHeat"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeats.excess_scrap_charged_per_sinteringHeat}
            onChange={(event) =>
              handleSinteringHeatChange(
                'excess_scrap_charged_per_sinteringHeat',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="chargedSinteringPerMonth"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Excess Scrap charged due to sintering per month (MT)
          </label>
          <input
            type="text"
            id="chargedSinteringPerMonth"
            name="chargedSinteringPerMonth"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={chargedSinteringPerMonth ? chargedSinteringPerMonth : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="yield_of_dri"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield of DRI (For DRI Customer) or Regular Scrap (For Scrap
            Customer) in regular heat (%)
          </label>
          <input
            type="text"
            id="yield_of_dri"
            name="yield_of_dri"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeats.yield_of_dri}
            onChange={(event) =>
              handleSinteringHeatChange('yield_of_dri', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="market_price_of_dri_mt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market Price of DRI/MT (For DRI Customer) or Regular Scrap /MT (For
            Scrap Customer) (Rs./MT)
          </label>
          <input
            type="text"
            id="market_price_of_dri_mt"
            name="market_price_of_dri_mt"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeats.market_price_of_dri_mt}
            onChange={(event) =>
              handleSinteringHeatChange(
                'market_price_of_dri_mt',
                event.target.value
              )
            }
          />
        </div>
      </div>

      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost of Excess Scrap charged due to Sintering heat per month (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costSinteringHeatPerMonth ? costSinteringHeatPerMonth : '0.00'}
            onChange={() => { }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost of Excess Scrap after replacing with DRI or Regular Scrap (per
            month) (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costOfReplacingWithDRI ? costOfReplacingWithDRI : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost of Excess Scrap charged in Sintering heat per MT (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={
              costOfchargedSinteringHeatPerMT
                ? costOfchargedSinteringHeatPerMT
                : '0.00'
            }
            onChange={() => { }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Scrap Cost saving in NRM due to less no. of Sintering heats (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>

      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>

      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="charge_mix_regular_heat"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield of overall charge mix in regular heat (%)
          </label>
          <input
            type="text"
            id="charge_mix_regular_heat"
            name="charge_mix_regular_heat"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeatsNRM.charge_mix_regular_heat}
            onChange={(event) =>
              handleSinteringHeatNRMChange(
                'charge_mix_regular_heat',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="yield_of_sintering_scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield of Sintering scrap (%)
          </label>
          <input
            type="text"
            id="yield_of_sintering_scrap"
            name="yield_of_sintering_scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeatsNRM.yield_of_sintering_scrap}
            onChange={(event) =>
              handleSinteringHeatNRMChange(
                'yield_of_sintering_scrap',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="market_price_sintering_scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market Price of Sintering scrap/MT (Rs./MT)
          </label>
          <input
            type="text"
            id="market_price_sintering_scrap"
            name="market_price_sintering_scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeatsNRM.market_price_sintering_scrap}
            onChange={(event) =>
              handleSinteringHeatNRMChange(
                'market_price_sintering_scrap',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="percentage_scrap_addition"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Percentage Scrap addition difference between sintering and regular
            heat (%)
          </label>
          <input
            type="text"
            id="percentage_scrap_addition"
            name="percentage_scrap_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeatsNRM.percentage_scrap_addition}
            onChange={(event) =>
              handleSinteringHeatNRMChange(
                'percentage_scrap_addition',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="excess_scrap_charged_per_sinteringHeat"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Excess Scrap charged per Sintering heat (MT)
          </label>
          <input
            type="text"
            id="excess_scrap_charged_per_sinteringHeat"
            name="excess_scrap_charged_per_sinteringHeat"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeatsNRM.excess_scrap_charged_per_sinteringHeat}
            onChange={(event) =>
              handleSinteringHeatNRMChange(
                'excess_scrap_charged_per_sinteringHeat',
                event.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Excess Scrap charged due to sintering per month (MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={
              chargedSinteringPerMonthNRM ? chargedSinteringPerMonthNRM : '0.00'
            }
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="yield_of_dri"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield of DRI (For DRI Customer) or Regular Scrap (For Scrap
            Customer) in regular heat (%)
          </label>
          <input
            type="text"
            id="yield_of_dri"
            name="yield_of_dri"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeatsNRM.yield_of_dri}
            onChange={(event) =>
              handleSinteringHeatNRMChange('yield_of_dri', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market Price of DRI/MT (For DRI Customer) or Regular Scrap /MT (For
            Scrap Customer) (Rs./MT)
          </label>
          <input
            type="text"
            id="market_price_of_dri_mt"
            name="market_price_of_dri_mt"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sinteringHeatsNRM.market_price_of_dri_mt}
            onChange={(event) =>
              handleSinteringHeatNRMChange(
                'market_price_of_dri_mt',
                event.target.value
              )
            }
          />
        </div>
      </div>

      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost of Excess Scrap charged due to Sintering heat per month (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={
              costSinteringHeatPerMonthNRM ? costSinteringHeatPerMonthNRM : '0.00'
            }
            onChange={() => { }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost of Excess Scrap after replacing with DRI or Regular Scrap (per
            month) (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costOfReplacingWithDRINRM ? costOfReplacingWithDRINRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost of Excess Scrap charged in Sintering heat per MT (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={
              costOfchargedSinteringHeatPerMTNRM
                ? costOfchargedSinteringHeatPerMTNRM
                : '0.00'
            }
            onChange={() => { }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Scrap Cost saving in NRM due to less no. of Sintering heats (INR/MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value= {scrapCostSavingNRM ? scrapCostSavingNRM : '0.00'}
            onChange={() => { }}
          />
        </div>
      </div>
    </>
  );
};

export default SinteringHeats;
