import React, { useEffect, useState, useRef } from "react";
import menuDot from "../../assets/images/icons/menuDot.svg";
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  json,
} from "react-router-dom";
import { useResetRecoilState } from "recoil";
import {
  general_details,
  furnace_details,
  charge_mix_details,
  carbon,
  alloying_addition,
  other_parameters,
} from "../../recoil/atom";
import {
  lining_reference,
  production_summary,
  lining_material_product_details,
  lining_material_consumption,
  dimensional_parameters,
  sintering_parameters,
} from "../../recoil/atom";
import ModalLayout from "../Common/ModalLayout";
import ErosionProfileLayout from "../ErosionProfileForm/ErosionProfileLayout";
import { trial_name } from '../../recoil/atom';
import { useRecoilState } from 'recoil'



export default function DataManagementCard({
  item,
  formattedDate,
  template_id,
  cardsDraft,
}) {
  const [openDropdownId, setOpenDropdownId] = useState(null); // Track open dropdown
  const dropdownRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const resetGeneralDetailsValue = useResetRecoilState(general_details);
  const resetFurnaceDetailsValue = useResetRecoilState(furnace_details);
  const resetChargeMixDetailsValue = useResetRecoilState(charge_mix_details);
  const resetCarbonValue = useResetRecoilState(carbon);
  const resetAlloyingadditionValue = useResetRecoilState(alloying_addition);
  const resetOtherParametersValue = useResetRecoilState(other_parameters);
  const resetLiningReferenceValue = useResetRecoilState(lining_reference);
  const resetProductionSummaryValue = useResetRecoilState(production_summary);
  const resetLiningMaterialProductDetailsValue = useResetRecoilState(
    lining_material_product_details
  );
  const resetLiningMaterialConsumptionValue = useResetRecoilState(
    lining_material_consumption
  );
  const resetDimensionalParametersValue = useResetRecoilState(
    dimensional_parameters
  );
  const resetSinteringParametersValue =
    useResetRecoilState(sintering_parameters);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [trialName, setTrialName] = useRecoilState(trial_name)
  const navigate = useNavigate();
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  // Toggle dropdown visibility for the selected card
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleView = async () => {
    localStorage.setItem("Trial", "view");
    localStorage.setItem("SelectedItem", JSON.stringify(item)); // Save item details
    if (template_id == 1 || template_id === 2) {
      navigate("/viewdailytrial");
    } else if (template_id === 3) {
      navigate("/viewerosionprofile");
    } else if (template_id === 4) {
      navigate("/viewdatacollection");
    } else if (template_id === 5) {
      navigate("/viewcostsheet");
    }
  };
  const handleEdit = async () => {
    localStorage.setItem("SelectedItem", JSON.stringify(item));
    localStorage.setItem('Trial', 'edit');
    if(template_id == 1 || template_id === 2){
      navigate('/viewdailytrial');
    }else if(template_id === 3){
      setTrialName('ErosionProfileEdit')
      localStorage.setItem('ErosionProfileEdit', 'ErosionProfileEdit')
      const erosionProfileID = item.id
      localStorage.setItem('erosionProfileID', erosionProfileID)
      setIsModalOpen(true);
      setModalTitle("Erosion Profile");
    }else if(template_id === 4){
      setIsModalOpen(true)
      const dataCollectionID = item.id
      console.log(dataCollectionID, "dataCollectionID")
      localStorage.setItem('dataCollectionID', dataCollectionID)
      setTrialName('DataCollectionedit')
      setModalTitle("Data Collection");
    }else if (template_id === 5){
      setTrialName('costSheetEdit')
      const costSheetID = item.id
      localStorage.setItem('costSheetID', costSheetID)
      localStorage.setItem('costSheetEdit', 'costSheetEdit')
      setIsModalOpen(true)
      setModalTitle("Cost Sheet");
    } 
  }
  
  const handleAdd = async () => {
    setIsModalOpen(true);
    localStorage.setItem("Trial", "add");
    if (template_id === 1) {
      setModalTitle("Daily Trail Monitoring");
      resetDailyTrialValues();
    } else {
      setModalTitle("NRM Performance");
      resetNRMValues();
    }
  };
  const resetNRMValues = () => {
    resetLiningReferenceValue();
    resetProductionSummaryValue();
    resetLiningMaterialProductDetailsValue();
    resetLiningMaterialConsumptionValue();
    resetDimensionalParametersValue();
    resetSinteringParametersValue();
  };

  const resetDailyTrialValues = () => {
    resetGeneralDetailsValue();
    resetFurnaceDetailsValue();
    resetChargeMixDetailsValue();
    resetCarbonValue();
    resetAlloyingadditionValue();
    resetOtherParametersValue();
  };

  return (
    <>
      <div className="card-grid">
        <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="flex items-start justify-between mb-2 pl-4 pt-4 relative">
            <div>
              <p className="text-sm text-gray-800 font-medium py-1 mb-0">
                {template_id === 1
                  ? `Daily Trial Monitoring_${item?.dataFile?.customer?.organization_name ?? "N/A"}`
                  : template_id === 2
                    ? `NRM Performance_${item?.dataFile?.customer?.organization_name ?? "N/A"}`
                    : template_id === 3
                      ? `Erosion Profile_${item?.dataFile?.customer?.organization_name ?? "N/A"}`
                      : template_id === 4
                        ? `Data Collection_${item?.dataFile?.customer?.organization_name ?? "N/A"}`
                        : template_id === 5
                          ? `Cost Sheet_${item?.dataFile?.customer?.organization_name ?? "N/A"}`
                          : "N/A"}
              </p>
            </div>
            <button
              type="button"
              className="flex mx-3 text-sm dark:focus:ring-gray-600"
              id="menuDot"
              onClick={() => toggleDropdown(item.id)}
            >
              <span className="sr-only">Open user menu</span>
              <img
                className="w-6 h-6 rounded-full"
                src={menuDot ?? ""}
                alt="menu"
              />
            </button>
            {openDropdownId === item.id && (
              <div
                id="userDropdown"
                ref={dropdownRef}
                className="absolute z-10 top-4 right-5 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200 w-full"
                  aria-labelledby="avatarButton"
                >
                  <li>
                    <button
                      onClick={handleView}
                      className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      View
                    </button>
                  </li>

                  {template_id === 1 &&
                    item?.general_details[0]?.submission_status === "draft" && (
                      <>
                        <li>
                          <button
                            className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={handleAdd}
                          >
                            Add
                          </button>
                        </li>
                        <li>
                          <button
                            className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </li>
                      </>
                    )}

                  {template_id === 2 &&
                    item?.lining_reference[0]?.submission_status ===
                      "draft" && (
                      <>
                        <li>
                          <button
                            className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={handleAdd}
                          >
                            Add
                          </button>
                        </li>
                        <li>
                          <button
                            className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </li>
                      </>
                    )}
              {(template_id === 3 || template_id === 4 || template_id === 5) && 
              item?.submission_status === "draft" && (
                <>
                  <li>
                    <button
                      className="block text-left w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={handleEdit}
                    >
                      Edit
                    </button>
                  </li>
                </>
            )}

                </ul>
              </div>
            )}
          </div>

          <div className="mb-2 w-full pl-4">
            <p className="text-sm text-gray-800 font-medium py-1 mb-0">
              {item?.dataFile?.customer?.organization_name ??
                "Organization Name"}
            </p>
          </div>
          <div className="mb-2 w-full pl-4">
            <p className="text-sm text-gray-800 font-medium py-1 mb-0">
              {item?.dataFile?.segment?.name ?? "Segment Name"}
            </p>
          </div>
          <div className="mb-3 w-full pl-4">
            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              {template_id === 4 || template_id === 5 || template_id === 3
                ? item.submission_status
                : template_id === 2
                  ? item?.lining_reference[0]?.submission_status
                  : (item?.general_details?.[0]?.submission_status ??
                    "Unknown Status")}
            </span>
          </div>
          <div className="w-full px-4 py-2 border-t flex justify-between items-center">
            {/* Created On */}
            <p className="text-xs font-normal text-gray-400">
              Created On: {formattedDate ?? "Date Not Available"}
            </p>

            {/* Created By */}
            <div className="flex items-center relative">
              <p className="text-xs font-normal text-gray-400">Created By:</p>
              <div
                className="w-8 h-8 flex items-center text-sm justify-center rounded-full bg-gray-200 text-blue-800 font-medium ml-2 cursor-pointer"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {item?.dataFile?.user?.firstname?.charAt(0) ?? ""}
                {item?.dataFile?.user?.lastname?.charAt(0) ?? ""}
              </div>

              {/* Tooltip */}
              {showTooltip && (
                <div className="absolute top-full left-0 mt-2 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                  {item?.dataFile?.user?.firstname ?? ""}{" "}
                  {item?.dataFile?.user?.lastname ?? ""}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ModalLayout closeModal={closeModal} modalTitle={modalTitle} />
      )}
    </>
  );
}
